/* eslint-disable camelcase */
import {
    ITasksFileDeleteRequest,
    ITasksFileDeleteResponse,
    ITasksFilesCountRequest,
    ITasksFilesCountResponse,
    ITasksFilesTableRequest,
    ITasksFilesTableSet,
    ITasksFileUpdateDescriptionResponse,
} from 'CommonTypes/Files/TasksFilesTable';

import emptySplitApi from 'Endpoints/emptySplitApi';

import ITasksFileUpdateDescriptionFrontRequest from './types/ITasksFileUpdateDescriptionFrontRequest';

export const tasksFilesApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({

        getTasksFilesCount: build.query<
            ITasksFilesCountResponse,
            ITasksFilesCountRequest
        >({
            query: params => ({
                url: 'files/count',
                params,
            }),
            providesTags: (result, error, { taskId }) => [
                { type: 'GetTasksFilesCount', taskId },
            ],
        }),

        getTasksFiles: build.query<
            ITasksFilesTableSet[],
            ITasksFilesTableRequest
        >({
            query: params => ({
                url: 'files',
                params,
            }),
            providesTags: (result, error, { taskId }) => [
                { type: 'GetTasksFiles', taskId },
            ],
        }),

        deleteFileFromTask: build.mutation<
            ITasksFileDeleteResponse,
            ITasksFileDeleteRequest
        >({
            query: ({ id_file, id_task }) => {
                const bodyFormData = new FormData();
                bodyFormData.append('id_task', String(id_task));
                bodyFormData.append('id_file', String(id_file));
                return {
                    url: `//${window.location.host}/files/task_attach_delete.php`,
                    method: 'POST',
                    body: bodyFormData,
                };
            },
            invalidatesTags: (result, error, { id_task }) => [
                { type: 'GetTasksFiles', taskId: id_task },
                { type: 'GetTasksFilesCount', taskId: id_task },
            ],
        }),

        updateFilesDescription: build.mutation<
            ITasksFileUpdateDescriptionResponse,
            ITasksFileUpdateDescriptionFrontRequest
        >({
            query: ({ fileId, ...rest }) => ({
                url: `files/${fileId}/description`,
                method: 'PATCH',
                body: rest,
            }),
            invalidatesTags: (result, error, { taskId }) => [
                { type: 'GetTasksFiles', taskId },
            ],
        }),

    }),
});

export const {
    useGetTasksFilesCountQuery,
    useGetTasksFilesQuery,
    useDeleteFileFromTaskMutation,
    useUpdateFilesDescriptionMutation,
} = tasksFilesApi;
