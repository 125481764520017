/* eslint-disable import/prefer-default-export */
import { RootState } from 'Utils/store/store';

import { IPriceListTreeTableReducer } from '../types/PriceListTreeTableSlice';

export type State = RootState & IPriceListTreeTableReducer;

export const getSelectedCategoryId = (state: State) => state
    .priceListTreeTableReducer.selectedCategory;
export const getSearchText = (state: State) => state
    .priceListTreeTableReducer.searchText;
export const getPriceIdForDelete = (state: State) => state
    .priceListTreeTableReducer.priceIdForDelete;
export const getPriceIdForEdit = (state: State) => state
    .priceListTreeTableReducer.priceIdForEdit;
export const getPriceIdForCopy = (state: State) => state
    .priceListTreeTableReducer.priceIdForCopy;
export const getCreatePrice = (state: State) => state
    .priceListTreeTableReducer.createPrice;
export const getCategoryIdForEdit = (state: State) => state
    .priceListTreeTableReducer.categoryIdForEdit;
export const getAddCategoryDialogIsOpen = (state: State) => state
    .priceListTreeTableReducer.categoryDialogIsOpen;
export const getSelectionMode = (state: State) => state
    .priceListTreeTableReducer.selectionMode;
