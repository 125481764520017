import { IPricesCategoriesAddRequest, IPricesCategoriesAddResponse } from 'CommonTypes/Prices/Prices';
import {
    IPriceCategory, IPriceCategoryInfo, IPriceCategoryInfoRequest, IPriceCategoryTreeView,
    IPriceCategoryUpdateRequest,
    IPriceCategoryUpdateResponse,
} from 'CommonTypes/Prices/PricesCategories';

import emptySplitApi from 'Endpoints/emptySplitApi';

export const pricesCategoriesApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({

        getPricesCategory: build.query<
            IPriceCategoryInfo,
            IPriceCategoryInfoRequest
        >({
            query: ({ categoryId, ...rest }) => ({
                url: `prices/categories/category/${categoryId}`,
                params: rest,
            }),
            providesTags: (result, error, { categoryId }) => [
                { type: 'GetPricesCategory', categoryId },
            ],
        }),

        updatePricesCategory: build.mutation<
            IPriceCategoryUpdateResponse,
            IPriceCategoryUpdateRequest
        >({
            query: ({ categoryId, ...rest }) => ({
                url: `prices/categories/category/${categoryId}`,
                body: rest,
                method: 'PUT',
            }),
            invalidatesTags(response, error, { categoryId }) {
                if (!response?.success) {
                    return [];
                }

                return [
                    { type: 'GetPricesCategory', categoryId },
                    'GetPricesByCategoryId',
                ];
            },
        }),

        getPricesCategories: build.query<
            IPriceCategory[],
            void
        >({
            query: () => ({
                url: 'prices/categories',
            }),
            providesTags: ['GetPricesByCategoryId'],
        }),

        getPricesCategoriesByTree: build.query<
            IPriceCategoryTreeView[],
            void
        >({
            query: () => ({
                url: 'prices/categories/tree-format',
            }),
            providesTags: ['GetPricesByCategoryId'],
        }),

        addPricesCategory: build.mutation<
            IPricesCategoriesAddResponse,
            IPricesCategoriesAddRequest
        >({
            query: body => ({
                url: 'prices/categories',
                method: 'POST',
                body,
            }),
            invalidatesTags(response) {
                if (!response?.success) {
                    return [];
                }

                return ['GetPricesByCategoryId'];
            },
        }),

    }),
});

export const {
    useGetPricesCategoryQuery,
    useUpdatePricesCategoryMutation,
    useGetPricesCategoriesQuery,
    useGetPricesCategoriesByTreeQuery,
    useAddPricesCategoryMutation,
} = pricesCategoriesApi;
