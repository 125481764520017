import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@mui/material';

import { createRoot } from 'react-dom/client';

import theme from 'Lib/Mui/theme';
import Action from 'Lib/Notistack/DefaultAction';
import StyledSnackbarProvider from 'Lib/Notistack/StyledSnackbarProvider';
import container from 'Services/container';

import NoticSnackbar from '../components/NoticSnackbar';
import { INotificationState } from '../types/SliceState';
import { setMessage } from '../utils/notificationsReducer';

let flag = false;

function renderNoticBlock() {
    const { store } = container;
    const root = document.getElementById('notic_block') as HTMLDivElement;
    const rootContainer = createRoot(root);

    rootContainer.render(
        <StrictMode>
            <ThemeProvider theme={theme}>
                <StyledSnackbarProvider
                    action={snackbarId => Action(snackbarId)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    hideIconVariant
                >
                    <Provider store={store}>
                        <NoticSnackbar />
                    </Provider>
                </StyledSnackbarProvider>
            </ThemeProvider>
        </StrictMode>,
    );
}

function showMessage(message: INotificationState) {
    if (!flag) {
        renderNoticBlock();
        flag = true;
    }

    const { store } = container;
    store.dispatch(setMessage(message));
}

export default () => {
    const { events } = container;

    events.on(
        'notifications__showMessage',
        showMessage,
        false,
    );
};
