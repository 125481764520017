import React from 'react';

import { Delete, Download } from '@mui/icons-material';
import {
    GridActionsCellItem,
    GridActionsColDef,
    GridRowParams,
} from '@mui/x-data-grid';

import openDownloadFile from 'Domain/AttachViewer/models/openDownloadFile.js';

import { IFilesTableColumnsProps } from '../types/FilesTable';

import columns from './filesTableColumns';

function useColumnsFilesTable({ deleteCallback }: IFilesTableColumnsProps) {
    const actions: GridActionsColDef = {
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params: GridRowParams) => [
            <GridActionsCellItem
                icon={<Download />}
                label="Скачать"
                onClick={() => {
                    const { sname = '', name = '' } = params.row;
                    openDownloadFile(sname, name);
                }}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                showInMenu={undefined}
            />,
            <GridActionsCellItem
                icon={<Delete />}
                label="Удалить"
                onClick={() => {
                    if (params?.id) {
                        deleteCallback(+(params?.id || 0));
                    }
                }}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                showInMenu={undefined}
            />,
        ],
    };

    const columnsUpdated = [
        ...columns,
        actions,
    ];

    return columnsUpdated;
}

export default useColumnsFilesTable;
