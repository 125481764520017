import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@mui/material';

import { Root, createRoot } from 'react-dom/client';

import theme from 'Lib/Mui/theme';
import Action from 'Lib/Notistack/DefaultAction';
import StyledSnackbarProvider from 'Lib/Notistack/StyledSnackbarProvider';
import container from 'Services/container';

import PriceListTreeTableSelectionWindow from '../components/PriceListTreeTableSelectionWindow';
import { IInitPriceListTreeTableProps } from '../types/EventsPriceListTreeTable';
import priceListTreeTableSlice, { setSelectionMode } from '../utils/priceListTreeTableSlice';

let rootContainer: Root;

function initPriceListTreeTable({ taskId }: IInitPriceListTreeTableProps) {
    const root = document.getElementById(`price__treeTableList-container${taskId}`) as HTMLDivElement;
    rootContainer = createRoot(root);

    const { store, injectReducer } = container;
    injectReducer('priceListTreeTableReducer', priceListTreeTableSlice);

    store.dispatch(setSelectionMode(true));

    rootContainer.render(
        <StrictMode>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <StyledSnackbarProvider
                        action={snackbarId => Action(snackbarId)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        hideIconVariant
                    >
                        <PriceListTreeTableSelectionWindow taskId={taskId} />
                    </StyledSnackbarProvider>
                </ThemeProvider>
            </Provider>
        </StrictMode>,
    );
}

function closePriceListTreeTableWindow() {
    rootContainer?.unmount();

    const { store } = container;
    store.dispatch(setSelectionMode(false));
}

export default () => {
    const { events } = container;

    events.on(
        'price__treeListTable:startSelection',
        initPriceListTreeTable,
        false,
    );

    events.on(
        'price__treeListTable:closeSelection',
        closePriceListTreeTableWindow,
        false,
    );
};
