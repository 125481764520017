import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    TextField,
} from '@mui/material';

import { setSearchText } from '../utils/priceListTreeTableSlice';

function PriceSearchFieldComponent() {
    const dispatch = useDispatch();
    const [value, setValue] = useState('');

    const handleButtonSearchClick = useCallback(() => {
        dispatch(setSearchText(value || null));
    }, [dispatch, value]);

    const handleClearButtonClick = useCallback(() => {
        setValue('');
        dispatch(setSearchText(null));
    }, [dispatch]);

    const handleKeyPress = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            dispatch(setSearchText(value || null));
        }
    }, [dispatch, value]);

    const handleValueChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    }, []);

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap="5px"
        >
            <TextField
                label="Поиск"
                variant="outlined"
                size="small"
                value={value}
                onChange={handleValueChange}
                onKeyDown={handleKeyPress}
                InputProps={{
                    endAdornment: !!value && (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClearButtonClick}
                                edge="end"
                                size="small"
                            >
                                <ClearIcon sx={{ fontSize: '0.9em' }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Button
                variant="contained"
                color="secondary"
                size="square"
                onClick={handleButtonSearchClick}
            >
                <SearchIcon />
            </Button>
        </Box>
    );
}

export default PriceSearchFieldComponent;
