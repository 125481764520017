/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable camelcase */

export default async (taskId, rowsIds) => {
    await app.modules.use('app.sales');

    app.sales.oneToList(taskId, () => {
        function task_save_param(id, name, value, callback) {
            app.fetch('ajax/task_save_a_param.php', { id, name, value }, 'POST', 'text').then((d) => {
                const res = parseInt($.trim(d));
                if (res >= 1) {
                    if (typeof (callback) === 'function') { callback(); } else if (typeof (callback) === 'string') { app.msg(callback, '1'); } else { app.msg('Сохранено', '1'); }
                } else {
                    app.error(d);
                }
            });
        }

        const id_task = taskId;
        const global_summ = app.sales.tadiGetPriceSumm(id_task);
        const sumel = document.getElementById(`tadi_total${id_task}`);

        if (!sumel || !(parseInt(sumel.innerText).toFixed(2) == global_summ.toFixed(2))) {
            task_save_param(id_task, 'total', global_summ, () => { app.msg('Данные успешно обновлены', 1); });
        }

        if (sumel && !(parseInt(sumel.innerText).toFixed(2) == global_summ.toFixed(2))) {
            sumel.innerText = global_summ.toFixed(2);
            sumel.animate('tada');
        }

        rowsIds.forEach((rowId) => {
            app.sales.saveTadiPriceRow(rowId, taskId, 0);
        });
    });
};
