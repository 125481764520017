import React from 'react';

import { Grid } from '@mui/material';

import { Autocomplete } from 'Ui/Form/Autocomplete';
import { TextField } from 'Ui/Form/TextField';

import { ICategoryFormProps } from '../types/CategoryForm';

function CategoryForm({
    projects,
    categories,
    users,
}: ICategoryFormProps) {
    return (
        <Grid container rowGap={2}>
            <Grid item xs={12}>
                <TextField
                    name="name"
                    label="Наименование"
                    fullWidth
                    required={{
                        required: {
                            value: true,
                            message: 'Поле не может быть пустым',
                        },
                        maxLength: {
                            value: 250,
                            message: 'Превышено максимальное количество символов',
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    name="project"
                    label="Проект"
                    options={projects}
                    blurOnSelect
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    name="parentCategory"
                    label="Категория"
                    options={categories}
                    blurOnSelect
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    name="users"
                    label="Пользователи"
                    options={users}
                    blurOnSelect
                    multiple
                />
            </Grid>
        </Grid>
    );
}

export default CategoryForm;
