import React from 'react';

import FilesTableDataGrid from './FilesTableDataGrid';
import { IFilesTableViewProps } from './types/FilesTable';

function FilesTabView({ taskId }: IFilesTableViewProps) {
    return (
        <FilesTableDataGrid taskId={taskId} />
    );
}

export default FilesTabView;
