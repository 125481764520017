import React from 'react';

import { useGetTasksHistoryCountByTaskIdQuery } from 'Endpoints/Tasks/tasks.api';

interface IProps {
    taskId: number;
}

function HistoryTabCount({ taskId }: IProps) {
    const { data: { count } = { count: 0 } } = useGetTasksHistoryCountByTaskIdQuery({ taskId });

    return (
        <>
            { Boolean(count) && (
                <span>
                    &nbsp;(
                    {count}
                    )
                </span>
            ) }
        </>
    );
}

export default HistoryTabCount;
