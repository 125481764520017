import React from 'react';

import { Button } from '@mui/material';

import { IPriceWindowFooterProps } from './types/PriceWindowFooter';

function PriceWindowFooter({ handleSubmitClick, handleCloseButtonClick }: IPriceWindowFooterProps) {
    return (
        <>
            <Button variant="contained" onClick={handleSubmitClick}>Сохранить</Button>
            <Button variant="outlined" onClick={handleCloseButtonClick}>Отмена</Button>
        </>
    );
}

export default PriceWindowFooter;
