import React from 'react';

import { GridColumns } from '@mui/x-data-grid';

import { ITasksFilesTableSet } from 'CommonTypes/Files/TasksFilesTable';

const columns: GridColumns<ITasksFilesTableSet> = [
    {
        field: 'id',
        headerName: '№',
        width: 100,
    },
    {
        field: 'name',
        headerName: 'Наименование',
        flex: 1,
        renderCell: params => (
            <span style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                {params.value}
            </span>
        ),
    },
    {
        field: 'typeName',
        headerName: 'Тип',
        width: 200,
    },
    {
        field: 'description',
        headerName: 'Описание',
        editable: true,
        width: 300,
    },
];

export default columns;
