import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Icon } from '@mui/material';
import {
    DataGridPremium as DataGrid,
    GridActionsCellItem,
    GridActionsColDef,
    GridColumns,
    GridRowParams,
} from '@mui/x-data-grid-premium';

import { ITimesheetAddBlankRecordResponse, ITimesheetDeleteRecordResponse, ITimesheetRecordUpdateDatesResponse } from 'CommonTypes/Timesheets/TimesheetTableEdit';

import ModalConfirmSimpleWindow from 'Domain/Common/ConfirmsModals/ModalConfirmSimpleWindow';
import valueFormatterDateTime from 'Domain/Helpers/valueFormatterDateTime';

import {
    useAddNewBlankTimesheetRecordMutation,
    useDeleteTimesheetRecordByRecordIdMutation,
    useGetTimesheetRecordAccessQuery,
    useGetTimesheetsRecordsByTaskIdQuery,
} from 'Endpoints/Timesheets/timesheets.api';

import { isTimesheetTabActivated } from 'fsrc/Task/store/activeTabsFromTasksFormSelector';
import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';

import TimesheetRecordModal from './TimesheetRecordModal';

interface IProps {
    taskId: number;
}

const columns: GridColumns = [
    {
        field: 'id',
        headerName: '#',
        width: 100,
    },
    {
        field: 'start',
        headerName: 'Начало',
        type: 'dateTime',
        width: 200,
        valueFormatter: valueFormatterDateTime,
    },
    {
        field: 'finish',
        headerName: 'Завершение',
        type: 'dateTime',
        width: 200,
        valueFormatter: valueFormatterDateTime,
    },
    {
        field: 'duration',
        headerName: 'Продолжительность (ч.)',
        type: 'number',
        valueFormatter: params => +((Math.round((params.value / 60) * 100) / 100).toFixed(2)),
        width: 250,
    },
    {
        field: 'userId',
        headerName: '#пользователя',
        type: 'number',
    },
    {
        field: 'userName',
        headerName: 'Пользователь',
        width: 300,
    },
    {
        field: 'comment',
        headerName: 'Комментарий',
        valueFormatter: params => (params.value ? params.value.replace(/<[^>]*>?/gm, '') : params.value),
        flex: 1,
    },
];

function TimesheetTableView({ taskId }: IProps) {
    const tabIsActivated = useSelector(isTimesheetTabActivated);
    const { success, error, warning } = useEnqueueSnackbar();

    const [deleteRecord] = useDeleteTimesheetRecordByRecordIdMutation();
    const [addBlankRecord] = useAddNewBlankTimesheetRecordMutation();

    const [recordIdForDelete, setRecordIdForDelete] = useState(0);
    const [recordIdForEdit, setRecordIdForEdit] = useState<number | null>(null);

    const { data: accessResult } = useGetTimesheetRecordAccessQuery(
        { recordId: recordIdForDelete || recordIdForEdit || 0, taskId },
        {
            skip: !(recordIdForDelete || recordIdForEdit),
        },
    );

    const { data = [] } = useGetTimesheetsRecordsByTaskIdQuery(
        { taskId },
        {
            skip: !tabIsActivated,
        },
    );

    const messageResponse = useCallback((field: 'add' | 'delete' | 'start' | 'finish', response: ITimesheetDeleteRecordResponse | ITimesheetRecordUpdateDatesResponse | ITimesheetAddBlankRecordResponse) => {
        if (response?.success) {
            if (field === 'delete') {
                success('Запись успешно удалена');
            }

            if (field === 'start' || field === 'finish') {
                success('Дата успешно изменена, продолжительность пересчитана');
            }

            if (field === 'add') {
                success('Запись успешно добавлена');
            }
        } else if (response?.permissions) {
            warning('Нет прав на редактирование этой записи');
        } else {
            error('На сервере возникла ошибка');
        }
    }, [error, success, warning]);

    const onModalDeleteCloseHandler = useCallback((result: boolean) => {
        if (result) {
            const deleteData = {
                recordId: recordIdForDelete,
                taskId,
            };
            deleteRecord(deleteData).unwrap().then(response => messageResponse('delete', response));
        }

        setRecordIdForDelete(0);
    }, [deleteRecord, messageResponse, recordIdForDelete, taskId]);

    const columnsUpdated = useMemo(() => {
        const actions: GridActionsColDef = {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Изменить"
                    onClick={() => setRecordIdForEdit(+(params?.id || 0))}
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    showInMenu={undefined}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Удалить"
                    onClick={() => setRecordIdForDelete(+(params?.id || 0))}
                    placeholder={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    showInMenu={undefined}
                />,
            ],
        };

        const resultColumns = [
            ...columns,
            actions,
        ];

        return resultColumns;
    }, []);

    const addBlankRecordButtonClickHandle = useCallback(() => {
        addBlankRecord({ taskId }).unwrap().then(response => messageResponse('add', response));
    }, [addBlankRecord, messageResponse, taskId]);

    const handleFormSubmit = useCallback(() => {
        setRecordIdForEdit(null);
    }, []);

    useEffect(() => {
        if (accessResult && accessResult?.permissions) {
            setRecordIdForEdit(null);
            setRecordIdForDelete(0);
            warning('Нет прав на редактирование этой записи');
        }
    }, [accessResult, accessResult?.permissions, warning]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '10px',
                    marginBottom: '15px',
                }}
            >
                <Button variant="contained" onClick={addBlankRecordButtonClickHandle} startIcon={<Icon><i className="fa fa-plus" /></Icon>}>
                    Добавить запись
                </Button>
            </div>
            <DataGrid
                columns={columnsUpdated}
                rows={data}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            userId: false,
                        },
                    },
                    aggregation: {
                        model: {
                            duration: 'sum',
                        },
                    },
                }}
                experimentalFeatures={{ aggregation: true, newEditingApi: true }}
            />
            { (Boolean(recordIdForDelete) && accessResult?.success)
            && (
                <ModalConfirmSimpleWindow
                    isOpen
                    title="Вы действительно хотите удалить запись?"
                    describe="Запись будет удалена..."
                    closeHandle={onModalDeleteCloseHandler}
                />
            ) }

            { (typeof recordIdForEdit === 'number' && accessResult?.success)
            && (
                <TimesheetRecordModal
                    taskId={taskId}
                    recordId={recordIdForEdit}
                    handleSubmitForm={handleFormSubmit}
                />
            ) }
        </>
    );
}

export default TimesheetTableView;
