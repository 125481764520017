import React from 'react';

import { Button } from '@mui/material';

import { IPriceListTreeTableSelectionWindowFooterProps } from '../types/PriceListTreeTableSelectionWindowFooter';

function PriceListTreeTableSelectionWindowFooter(
    { handleCloseButtonClick, handleSubmitClick }: IPriceListTreeTableSelectionWindowFooterProps,
) {
    return (
        <>
            <Button variant="contained" onClick={handleSubmitClick}>Добавить</Button>
            <Button variant="outlined" onClick={handleCloseButtonClick}>Отмена</Button>
        </>
    );
}

export default PriceListTreeTableSelectionWindowFooter;
