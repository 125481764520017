import { initModule } from 'Services/initModule.js';

export default {
    '/sales/tasks-panel': {
        as: 'salesTasksPanel',
        uses() {
            import(
                /* webpackChunkName: "salestaskspanel" */ '../../Sales/controllers/SalesTasksPanelController'
            ).then((module) => {
                initModule(module.default);
            });
        },
    },
};
