import emptySplitApi from 'Endpoints/emptySplitApi';
import { IContragentSimpleSet, IContragentsFirmSimpleSetResponse } from 'CommonTypes/Contragents/ContragentsSimpleSet';

export const contragentsApi = emptySplitApi
    .injectEndpoints({
        endpoints: build => ({

            getContragentsSimpleSet: build.query<IContragentSimpleSet[], void>({
                query: () => ({
                    url: 'contragents',
                }),
            }),

            getFirmsSimpleSet: build.query<IContragentsFirmSimpleSetResponse[], void>({
                query: () => ({
                    url: 'contragents/firms',
                }),
            }),

        }),
    });

export const {
    useGetContragentsSimpleSetQuery,
    useGetFirmsSimpleSetQuery,
} = contragentsApi;
