import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Paper } from '@mui/material';

import { usePricesAddToTaskMutation } from 'Endpoints/Prices/prices.api';
import { useCreateTaskMutation } from 'Endpoints/Tasks/tasks.api';

import showTask from 'fsrc/Task/models/showTask';
import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';
import container from 'Services/container';

import { getSelectedCategoryId, State } from '../utils/priceListTreeTableSelectors';
import {
    setCategoryDialogIsOpen,
    setCategoryIdForEdit,
    setCreatePrice,
    setPriceIdForCopy,
    setPriceIdForDelete,
} from '../utils/priceListTreeTableSlice';

import PriceSearchFieldComponent from './PriceSearchFieldComponent';

function PriceListToolbar() {
    const dispatch = useDispatch();
    const selectedCategoryId = useSelector(getSelectedCategoryId);

    const { success, warning, error } = useEnqueueSnackbar();

    const [addPrices] = usePricesAddToTaskMutation();
    const [createTask] = useCreateTaskMutation();

    const handleAddButton = useCallback(() => {
        dispatch(setCreatePrice(true));
    }, [dispatch]);

    const handleAddCategoryButtonClick = useCallback(() => {
        dispatch(setCategoryDialogIsOpen(true));
    }, [dispatch]);

    const handleEditCategoryButtonClick = useCallback(() => {
        if (!selectedCategoryId) {
            return warning('Не выбрана Категория');
        }
        dispatch(setCategoryIdForEdit(selectedCategoryId));
        return dispatch(setCategoryDialogIsOpen(true));
    }, [dispatch, selectedCategoryId, warning]);

    const copyPriceButtonClick = useCallback(() => {
        const { store } = container;
        const storeState = store.getState() as State;
        const { priceListTreeTableReducer: { selectedPrices = [] } } = storeState;
        if (selectedPrices?.length) {
            return dispatch(setPriceIdForCopy(selectedPrices[0].id));
        }

        return warning('Не выбрано ни одной позиции!');
    }, [dispatch, warning]);

    const deletePriceButtonClick = useCallback(() => {
        const { store } = container;
        const storeState = store.getState() as State;
        const { priceListTreeTableReducer: { selectedPrices = [] } } = storeState;
        if (selectedPrices?.length) {
            return dispatch(setPriceIdForDelete(selectedPrices[0].id));
        }

        return warning('Не выбрано ни одной позиции!');
    }, [dispatch, warning]);

    const createTaskButtonClick = useCallback(() => {
        const { store, events } = container;
        const storeState = store.getState() as State;
        const { priceListTreeTableReducer: { selectedPrices = [] } } = storeState;
        const { reducerUser: { contactId = 0 } } = storeState;
        if (selectedPrices?.length) {
            const taskData = {
                name: selectedPrices[0].name,
                project: selectedPrices[0].saleProjectId,
                contact: contactId,
            };
            createTask(taskData).unwrap().then((response) => {
                if (!response?.success || !response?.id) {
                    return error(
                        `Во время создания задачи возникла ошибка на сервере: ${
                            response?.error || ''}`,
                    );
                }

                const priceToTaskData = {
                    taskId: response.id,
                    prices: selectedPrices.map(
                        ({ id, count, saleProjectId }) => ({ id, count, projectId: saleProjectId }),
                    ),
                };
                addPrices(priceToTaskData);

                showTask(Number(response?.id || 0));
                return success(
                    'Создана задача №',
                    {
                        actionText: `${response?.id || 0}`,
                        callback: () => events.emit('oldAppInbox:openTask', response?.id || 0),
                    },
                );
            });
            return undefined;
        }

        return warning('Не выбрано ни одной позиции!');
    }, [addPrices, createTask, error, success, warning]);

    return (
        <Paper
            sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#f5f5f6',
                minHeight: '48px',
            }}
        >
            <Grid container alignItems="center">
                <Grid item xs={9} sx={{ paddingLeft: '5px', display: 'flex', gap: '10px' }}>
                    <Button
                        style={{ lineHeight: 0 }}
                        variant="contained"
                        color="secondary"
                        onClick={handleAddCategoryButtonClick}
                        startIcon={<AddIcon />}
                    >
                        Добавить Категорию
                    </Button>
                    <Button
                        style={{ lineHeight: 0 }}
                        variant="contained"
                        color="secondary"
                        onClick={handleEditCategoryButtonClick}
                        startIcon={<EditIcon />}
                    >
                        Редактировать Категорию
                    </Button>
                    <Button
                        style={{ lineHeight: 0 }}
                        variant="contained"
                        color="secondary"
                        onClick={handleAddButton}
                        startIcon={<AddIcon />}
                    >
                        Добавить прайс
                    </Button>

                    <Button
                        style={{ lineHeight: 0 }}
                        variant="contained"
                        color="secondary"
                        onClick={copyPriceButtonClick}
                        startIcon={<ContentCopyIcon />}
                    >
                        Копировать прайс
                    </Button>
                    <Button
                        style={{ lineHeight: 0 }}
                        variant="contained"
                        color="secondary"
                        onClick={deletePriceButtonClick}
                        startIcon={<Delete />}
                    >
                        Удалить прайс
                    </Button>
                    <Button
                        style={{ lineHeight: 0 }}
                        variant="contained"
                        color="secondary"
                        onClick={createTaskButtonClick}
                        startIcon={<AddShoppingCartIcon />}
                    >
                        Создать продажу
                    </Button>
                </Grid>
                <Grid item xs={3} sx={{ paddingRight: '5px' }}>
                    <PriceSearchFieldComponent />
                </Grid>
            </Grid>
        </Paper>
    );
}

export default PriceListToolbar;
