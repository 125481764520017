import { IStatesSimpleSetRequest, IStatesSimpleSetResponse } from 'CommonTypes/Tasks/States/StatesTypes';
import {
    ITasksHistoryRecordsRequest,
    ITasksHistoryRecordsResponse,
    ITasksHistoryRequest,
    ITasksHistoryResponse,
    ITasksHistoryUpdateStateIdRequest,
    ITasksHistoryUpdateStateIdResponse,
    ITasksHistoryUpdateRecordsDatesRequest,
    ITasksHistoryUpdateRecordsDatesResponse,
    ITasksHistoryDeleteRecordResponse,
    ITasksHistoryDeleteRecordRequest,
    ITasksHistoryClearByTaskIdResponse,
    ITasksHistoryClearByTaskIdRequest,
    ITasksHistoryAddRecordResponse,
    ITasksHistoryAddRecordRequest,
} from 'CommonTypes/Tasks/TasksTypes/TasksHistory';
import {
    ITasksLinksCountRequest,
    ITasksLinksCountResponse,
    ITasksLinksRequest, ITasksLinksResponse, ITasksLinksSetRequest, ITasksLinksSetResponse,
} from 'CommonTypes/Tasks/TasksTypes/TasksLinks';

import emptySplitApi from 'Endpoints/emptySplitApi';

import { ITaskData } from '../../../ButtonsModule/TestRTKModule/ITaskData';

import { ICreateTaskOldEndPointRequest, ICreateTaskOldEndPointResponse } from './types/CreateTaskOldEndPoint';

export const tasksApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({

        getTaskById: build.query<ITaskData, number>({
            query: id => ({
                url: 'tasks/get-task-by-id',
                params: {
                    taskId: id,
                },
            }),
        }),

        getTaskViewForm: build.query<any, number>({
            query: id => ({
                url: 'tasks/view-task',
                params: {
                    taskId: id,
                },
            }),
        }),

        getTasksLinks: build.query<ITasksLinksResponse[], ITasksLinksRequest>({
            query: ({ taskId }) => ({
                url: `tasks/links/${taskId}`,
            }),
            providesTags: (result, error, { taskId }) => [
                { type: 'TasksLinksById', taskId },
            ],
        }),

        getTasksLinksCountByTaskId: build.query<
            ITasksLinksCountResponse,
            ITasksLinksCountRequest
        >({
            query: ({ taskId }) => ({
                url: `tasks/links/${taskId}/count`,
            }),
        }),

        setTasksLink: build.mutation<ITasksLinksSetResponse, ITasksLinksSetRequest>({
            query: data => ({
                url: 'tasks/links',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, { source }) => [
                { type: 'TasksLinksById', taskId: source },
            ],
        }),

        getTasksHistoryCountByTaskId: build.query<ITasksHistoryResponse, ITasksHistoryRequest>({
            query: ({ taskId }) => ({
                url: `tasks/history/${taskId}/count`,
            }),
            providesTags: (result, error, { taskId }) => [
                { type: 'TasksHistoryCountByTaskId', taskId },
            ],
        }),

        getTasksHistoryRecordsByTaskId: build.query<
            ITasksHistoryRecordsResponse[],
            ITasksHistoryRecordsRequest
        >({
            query: ({ taskId }) => ({
                url: `tasks/history/${taskId}`,
            }),
            providesTags: (result, error, { taskId }) => [
                { type: 'TasksHistoryRecordsByTaskId', taskId },
            ],
        }),

        getTasksStatesByRouteId: build.query<
            IStatesSimpleSetResponse[],
            IStatesSimpleSetRequest
        >({
            query: ({ routeId }) => ({
                url: `tasks/states/by-route/${routeId}`,
            }),
        }),

        updateTasksHistoryRecordStateId: build.mutation<
            ITasksHistoryUpdateStateIdResponse,
            ITasksHistoryUpdateStateIdRequest
        >({
            query: ({ recordId, ...rest }) => ({
                url: `tasks/history/${recordId}/states`,
                method: 'PATCH',
                body: rest,
            }),
            async onQueryStarted({ taskId, recordId, stateId }, { dispatch, queryFulfilled }) {
                const { data: response } = await queryFulfilled;
                if (response?.success) {
                    dispatch(
                        tasksApi.util.updateQueryData(
                            'getTasksHistoryRecordsByTaskId',
                            { taskId },
                            records => records.map(
                                record => (+record.id === +recordId
                                    ? { ...record, stateId: +stateId } : record),
                            ),
                        ),
                    );
                }
            },
        }),

        updateTasksHistoryRecordDate: build.mutation<
            ITasksHistoryUpdateRecordsDatesResponse,
            ITasksHistoryUpdateRecordsDatesRequest
        >({
            query: ({ recordId, ...rest }) => ({
                url: `tasks/history/${recordId}/dates`,
                method: 'PATCH',
                body: rest,
            }),
            async onQueryStarted(
                {
                    taskId, recordId, dateType, newDate,
                },
                { dispatch, queryFulfilled },
            ) {
                const { data: response } = await queryFulfilled;
                if (response?.success) {
                    dispatch(
                        tasksApi.util.updateQueryData(
                            'getTasksHistoryRecordsByTaskId',
                            { taskId },
                            records => records.map(
                                record => (+record.id === +recordId
                                    ? { ...record, [`${dateType}Date`]: newDate, durationDays: response?.durationDays || 0 } : record),
                            ),
                        ),
                    );
                }
            },
        }),

        deleteTasksHistoryRecord: build.mutation<
            ITasksHistoryDeleteRecordResponse,
            ITasksHistoryDeleteRecordRequest
        >({
            query: ({ recordId, ...rest }) => ({
                url: `tasks/history/${recordId}`,
                method: 'DELETE',
                params: rest,
            }),
            async onQueryStarted(
                { taskId, recordId },
                { dispatch, queryFulfilled },
            ) {
                const { data: response } = await queryFulfilled;
                if (response?.success) {
                    dispatch(
                        tasksApi.util.updateQueryData(
                            'getTasksHistoryRecordsByTaskId',
                            { taskId },
                            records => records.filter(record => +record.id !== +recordId),
                        ),
                    );
                    dispatch(
                        tasksApi.util.updateQueryData(
                            'getTasksHistoryCountByTaskId',
                            { taskId },
                            draft => ({ count: draft.count - 1 }),
                        ),
                    );
                }
            },
        }),

        clearTasksHistoryByTaskId: build.mutation<
            ITasksHistoryClearByTaskIdResponse,
            ITasksHistoryClearByTaskIdRequest
        >({
            query: params => ({
                url: 'tasks/history',
                method: 'DELETE',
                params,
            }),
            async onQueryStarted(
                { taskId },
                { dispatch, queryFulfilled },
            ) {
                const { data: response } = await queryFulfilled;
                if (response?.success) {
                    dispatch(
                        tasksApi.util.updateQueryData(
                            'getTasksHistoryRecordsByTaskId',
                            { taskId },
                            records => [],
                        ),
                    );
                    dispatch(
                        tasksApi.util.updateQueryData(
                            'getTasksHistoryCountByTaskId',
                            { taskId },
                            draft => ({ count: 0 }),
                        ),
                    );
                }
            },
        }),

        addTasksHistoryNewRecord: build.mutation<
            ITasksHistoryAddRecordResponse,
            ITasksHistoryAddRecordRequest
        >({
            query: ({ taskId, ...rest }) => ({
                url: `tasks/history/${taskId}`,
                method: 'POST',
                body: rest,
            }),
            invalidatesTags: (result, error, { taskId }) => [
                { type: 'TasksHistoryRecordsByTaskId', taskId },
            ],
        }),

        createTask: build.mutation<
            ICreateTaskOldEndPointResponse,
            ICreateTaskOldEndPointRequest
        >({
            query({ name, project, contact }) {
                const bodyFormData = new FormData();
                bodyFormData.append('name', name);
                bodyFormData.append('project', String(project));
                bodyFormData.append('contact', String(contact));

                return {
                    url: `//${window.location.host}/ajax/qick_add_task.php`,
                    method: 'POST',
                    body: bodyFormData,
                };
            },
        }),

    }),
    overrideExisting: false,
});

export const {
    useGetTaskByIdQuery,
    useGetTaskViewFormQuery,
    useGetTasksLinksQuery,
    useGetTasksLinksCountByTaskIdQuery,
    useSetTasksLinkMutation,
    useGetTasksHistoryCountByTaskIdQuery,
    useGetTasksHistoryRecordsByTaskIdQuery,
    useGetTasksStatesByRouteIdQuery,
    useUpdateTasksHistoryRecordStateIdMutation,
    useUpdateTasksHistoryRecordDateMutation,
    useDeleteTasksHistoryRecordMutation,
    useClearTasksHistoryByTaskIdMutation,
    useAddTasksHistoryNewRecordMutation,
    useCreateTaskMutation,
} = tasksApi;
