import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    DataGridPremium as DataGrid,
    GridRowParams,
    GridSelectionModel,
    useGridApiRef,
} from '@mui/x-data-grid-premium';

import { IPriceSimpleSet } from 'CommonTypes/Prices/Prices';

import { useDeletePriceMutation, useGetPricesByCategoryIdQuery, useSearchPricesByTextQuery } from 'Endpoints/Prices/prices.api';

import { ConfirmDeleteModal } from 'Ui/Modal';

import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';

import useColumnsPriceListDataGrid from '../models/useColumnsPriceListDataGrid';
import { ISelectedPrice } from '../types/PriceListTreeTableSlice';
import {
    getPriceIdForDelete, getSearchText, getSelectedCategoryId, getSelectionMode,
} from '../utils/priceListTreeTableSelectors';
import {
    setPriceIdForCopy, setPriceIdForDelete, setPriceIdForEdit, setSelectedPrices,
} from '../utils/priceListTreeTableSlice';

function PriceListDataGridComponent() {
    const dataGridRef = useGridApiRef();

    const dispatch = useDispatch();
    const { warning } = useEnqueueSnackbar();

    const selectedCategoryId = useSelector(getSelectedCategoryId);
    const searchText = useSelector(getSearchText);
    const priceIdForDelete = useSelector(getPriceIdForDelete);

    const selectionMode = useSelector(getSelectionMode);

    const { data: options = [], isLoading, isFetching } = useGetPricesByCategoryIdQuery(
        { categoryId: selectedCategoryId || 0 },
        {
            skip: Boolean(!selectedCategoryId || searchText),
        },
    );

    const {
        data: searchOptions = [],
        isLoading: isLoadingSearch,
        isFetching: isFetchingSearch,
    } = useSearchPricesByTextQuery(
        { searchText: searchText || '' },
        {
            skip: !searchText,
        },
    );

    const [deletePrice] = useDeletePriceMutation();

    const deletePriceCallback = useCallback(
        (priceId: number) => dispatch(setPriceIdForDelete(priceId)),
        [dispatch],
    );
    const copyPriceCallback = useCallback(
        (priceId: number) => dispatch(setPriceIdForCopy(priceId)),
        [dispatch],
    );

    const modalDeleteCallback = useCallback((result: boolean) => {
        if (result && priceIdForDelete) {
            deletePrice({ priceId: priceIdForDelete }).unwrap().then((response) => {
                if (!response?.success && response?.error === 'DELETE_RESTRICT') {
                    warning('Удаление данной позиции невозможно, она используется в документах!');
                }
            });
        }
        dispatch(setPriceIdForDelete(null));
    }, [deletePrice, dispatch, priceIdForDelete, warning]);

    const handleRowDoubleClick = useCallback((params: GridRowParams<IPriceSimpleSet>) => {
        const { id = 0 } = params;
        if (id) {
            dispatch(setPriceIdForEdit(id));
        }
    }, [dispatch]);

    const handleRowsSelectChange = useCallback((selectionModel: GridSelectionModel) => {
        const selectedPrices: ISelectedPrice[] = selectionModel.map((id) => {
            const row = dataGridRef?.current?.getRow(id) as IPriceSimpleSet;
            const result = {
                id: Number(id),
                name: row?.name || '',
                count: 1,
                saleProjectId: row?.saleProjectId || 0,
            };
            return result;
        });
        dispatch(setSelectedPrices(selectedPrices || []));
    }, [dataGridRef, dispatch]);

    const updatedColumns = useColumnsPriceListDataGrid({ deletePriceCallback, copyPriceCallback });

    return (
        <>
            <DataGrid
                apiRef={dataGridRef}
                onRowDoubleClick={handleRowDoubleClick}
                rows={searchText ? searchOptions : options}
                columns={updatedColumns}
                loading={isLoading || isFetching || isLoadingSearch || isFetchingSearch}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            categoryId: false,
                            saleProjectId: false,
                            measureId: false,
                        },
                    },
                }}
                onSelectionModelChange={handleRowsSelectChange}
            />
            { priceIdForDelete && (
                <ConfirmDeleteModal
                    callback={modalDeleteCallback}
                />
            )}
        </>
    );
}

export default PriceListDataGridComponent;
