import React from 'react';

import { useGetTasksFilesCountQuery } from 'Endpoints/Files/tasksFiles.api';

import { IFilesTabCountProps } from './types/FilesTabCount';

function FilesTabCount({ taskId }: IFilesTabCountProps) {
    const { data } = useGetTasksFilesCountQuery({ taskId });

    return (
        <>
            {
                data?.count
                    ? (
                        <span>
                            &nbsp;(
                            {data.count}
                            )
                        </span>
                    ) : null
            }
        </>
    );
}

export default FilesTabCount;
