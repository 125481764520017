import { initModule } from 'Services/initModule.js';

export default {
    '/price/tree-table': {
        as: 'price__treeTable',
        uses() {
            import(
                /* webpackChunkName: "pricelisttreetable" */ '../../PriceListModule/components/PriceListTreeTable/controllers/PriceListTreeTableController'
            ).then(module => initModule(module.default));
        },
    },
};
