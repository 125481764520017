import webixInit from 'Lib/Webix/helpers/webixInit.js';

import initMainMenu from '../MainMenu/mainMenuController.js';
import sidemenuInit from '../Sidemenu/sidemenu.js';

import { router, initRouter } from './appRouter';
import uiBlock, { killWebix } from './config/blocks';
import setGlobals from './globals.js';
import container from './services/container';
import events from './utils/events';
import initApp from './utils/initApp';

import 'Application/assets/scss/datatable.original.scss';
import 'Application/assets/scss/styleTable.original.scss';
import 'Application/assets/scss/buttons.original.scss';
import 'Application/assets/scss/tabbar.original.scss';
import 'Application/assets/scss/accordion.orig.scss';
import 'Application/assets/scss/style.orig.scss';

setGlobals({ uiBlock, events, router });

function init() {
    webixInit();
    initRouter(events);
    sidemenuInit();
    initMainMenu(container);
}

initApp();

events.once('oldapp:loaded', init, false);
events.on('oldrouter:beforeRouting', killWebix, false);
