import {
    ITasksDescriptionRequest,
    ITasksDescriptionResponse,
    ITasksDescriptionUpdateRequest,
    ITasksDescriptionUpdateResponse,
} from 'CommonTypes/Tasks/DescriptionTab/TasksDescriptionTab';

import emptySplitApi from 'Endpoints/emptySplitApi';

export const tasksDescriptionApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({

        getTasksDescription: build.query<
            ITasksDescriptionResponse,
            ITasksDescriptionRequest
        >({
            query: params => ({
                url: 'tasks/description',
                params,
            }),
            providesTags: (result, error, { taskId }) => [
                { type: 'GetTasksDescription', taskId },
            ],
        }),

        updateTasksDescription: build.mutation<
            ITasksDescriptionUpdateResponse,
            ITasksDescriptionUpdateRequest
        >({
            query: body => ({
                url: 'tasks/description',
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error, { taskId }) => [
                { type: 'GetTasksDescription', taskId },
            ],
        }),

    }),
});

export const {
    useGetTasksDescriptionQuery,
    useUpdateTasksDescriptionMutation,
} = tasksDescriptionApi;
