import React, { SyntheticEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Paper } from '@mui/material';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';

import { useGetPricesCategoriesByTreeQuery } from 'Endpoints/Prices/pricesCategories.api';

import { getSelectionMode } from '../utils/priceListTreeTableSelectors';
import { setSelectedCategory } from '../utils/priceListTreeTableSlice';

function PriceListTreeComponent() {
    const dispatch = useDispatch();
    const { data: categoriesTree = [] } = useGetPricesCategoriesByTreeQuery();

    const selectionMode = useSelector(getSelectionMode);

    const handleTreeViewSelectedItemChange = useCallback((
        event: SyntheticEvent,
        itemIds: string | null,
    ) => {
        dispatch(setSelectedCategory(itemIds ? Number(itemIds) || null : null));
    }, [dispatch]);

    return (
        <Paper
            sx={{
                minHeight: 352,
                minWidth: 250,
                display: 'flex',
                flex: 1,
                height: selectionMode ? 'auto' : 'calc(100vh - 138px)',
                overflowY: 'auto',
            }}
        >
            <RichTreeView
                sx={{ width: '100%' }}
                items={categoriesTree}
                onSelectedItemsChange={handleTreeViewSelectedItemChange}
            />
        </Paper>
    );
}

export default PriceListTreeComponent;
