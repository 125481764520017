import React, { useCallback, useState } from 'react';

import { Icon } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';

import './assets/styles.orig.css';
import './assets/captions.orig.css';
import { CustomSlide, IAttachViwerProps } from 'Domain/AttachViewer/types/AttachViewer';

import openDownloadFile from './models/openDownloadFile.js';

function AttachViewer({
    currentIndex: initialCurrentIndex = 0,
    handleClose,
    slides,
}: IAttachViwerProps) {
    const [currentIndex, setCurrentIndex] = useState(initialCurrentIndex);

    const handleDownloadButtonClick = useCallback((sname: string, name: string) => {
        openDownloadFile(sname, name);
    }, []);

    return (
        <Lightbox
            open
            close={handleClose}
            index={currentIndex}
            slides={slides}
            plugins={[Captions]}
            toolbar={{
                buttons: [
                    <IconButton
                        onClick={
                            () => handleDownloadButtonClick(
                                slides[currentIndex].sname,
                                slides[currentIndex].name,
                            )
                        }
                    >
                        <Icon style={{ fontSize: '25px', color: 'white' }}>
                            <i className="fa fa-download" />
                        </Icon>
                    </IconButton>,
                    'close',
                ],
            }}
            render={{
                slide: ({ slide }) => {
                    const customSlide = slide as CustomSlide;
                    if (customSlide.isPdf) {
                        return (
                            <iframe
                                src={customSlide.src}
                                width="100%"
                                height="100%"
                                style={{ border: 'none' }}
                                title={customSlide.name}
                            />
                        );
                    }

                    if (customSlide.onlyDownload) {
                        return (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <h3 style={{ color: 'white' }}>{customSlide.name}</h3>
                                <p style={{ color: 'white' }}>{customSlide.description}</p>
                                <p style={{ color: 'white' }}>
                                    Просмотр недоступен, файл можно скачать
                                </p>
                            </div>
                        );
                    }

                    return (
                        <img
                            src={customSlide.src}
                            alt={customSlide.name}
                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                    );
                },
            }}
            on={{
                view: ({ index }) => setCurrentIndex(index),
            }}
        />
    );
}

export default AttachViewer;
