import React from 'react';

import { Grid } from '@mui/material';

import { Autocomplete } from 'Ui/Form/Autocomplete';
import { Checkbox } from 'Ui/Form/Checkbox';
import { TextField } from 'Ui/Form/TextField';

import { IPriceFormProps } from './types/PriceForm';

function PriceForm({
    categories,
    measures,
    contragents,
    pricesCurrencies,
    pricesVats,
}: IPriceFormProps) {
    return (
        <Grid container rowSpacing={1} spacing={1}>
            <Grid item xs={12}>
                <TextField
                    name="shortname"
                    label="Краткое наименование"
                    fullWidth
                    multiline
                    rows={4}
                    required={{
                        required: {
                            value: true,
                            message: 'Поле не может быть пустым',
                        },
                        maxLength: {
                            value: 250,
                            message: 'Превышено максимальное количество символов',
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="name"
                    label="Наименование"
                    fullWidth
                    multiline
                    rows={4}
                    required={{
                        required: {
                            value: true,
                            message: 'Поле не может быть пустым',
                        },
                        maxLength: {
                            value: 1000,
                            message: 'Превышено максимальное количество символов',
                        },
                    }}
                />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <h3>Основные сведения</h3>
                <TextField
                    name="article"
                    label="Артикул"
                    fullWidth
                />
                <Grid item xs={12}>
                    <Autocomplete
                        name="category"
                        label="Категория"
                        options={categories}
                        blurOnSelect
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        name="provider"
                        label="Поставщик"
                        options={contragents}
                        blurOnSelect
                    />
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Autocomplete
                            name="measure"
                            label="Единица измерения"
                            options={measures}
                            blurOnSelect
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Checkbox
                            name="service"
                            label="Услуга"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <h3>Цены</h3>

                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <TextField
                            name="purchasePrice"
                            label="Закупка"
                            type="number"
                            inputProps={{
                                sx: {
                                    paddingRight: '0',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            name="purchaseCurrency"
                            label="Валюта"
                            options={pricesCurrencies}
                            blurOnSelect
                            required
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            name="purchaseVat"
                            label="НДС"
                            options={pricesVats}
                            blurOnSelect
                            required
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <TextField
                            name="sellingPrice"
                            label="Продажа"
                            type="number"
                            inputProps={{
                                sx: {
                                    paddingRight: '0',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            name="sellingCurrency"
                            label="Валюта"
                            options={pricesCurrencies}
                            blurOnSelect
                            required
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            name="sellingVat"
                            label="НДС"
                            options={pricesVats}
                            blurOnSelect
                            required
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        name="manufacturer"
                        label="Производитель"
                        options={contragents}
                        blurOnSelect
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <h3>Описание</h3>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="url"
                    label="Ссылка"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="note"
                    label="Описание"
                    rows={3}
                    multiline
                    fullWidth
                />
            </Grid>
        </Grid>
    );
}

export default PriceForm;
