import { IPriceCreateRequest } from 'CommonTypes/Prices/Prices';

import { IPriceFormState } from '../types/PriceForm';

export default (state: IPriceFormState): IPriceCreateRequest => ({
    name: state.name || '',
    shortname: state.shortname || '',
    photo: null,
    article: state.article || null,
    measureId: state.measure?.id || 0,
    serviceFlag: state.service ? 1 : 0,
    categoryId: state.category?.id || 0,
    providerId: state.provider?.id || null,
    manufacturerId: state.manufacturer?.id || null,
    sellingPrice: state.sellingPrice,
    sellingVat: state.sellingVat?.id || 0,
    sellingCurrency: state.sellingCurrency?.id || 0,
    purchasePrice: state.purchasePrice,
    purchaseCurrency: state.purchaseCurrency?.id || 0,
    purchaseVat: state.purchaseVat?.id || 0,
    url: state.url || null,
    note: state.note || null,
});
