import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@mui/material';

import { Root, createRoot } from 'react-dom/client';

import { IIFieldsVisibleConfigReqeust } from 'CommonTypes/Forms/FormsFieldsConfig';

import theme from 'Lib/Mui/theme';
import Action from 'Lib/Notistack/DefaultAction';
import StyledSnackbarProvider from 'Lib/Notistack/StyledSnackbarProvider';
import container from 'Services/container';

import ServiceFunctionsView from '../components/serviceFunctionsForm/ServiceFunctionsView';

type TData = {
    taskId: number;
} & IIFieldsVisibleConfigReqeust

let rootContainer: Root;

function initFormForOldTaskForm({ taskId, taskTypeId, formTypeId }: TData) {
    const root = document.getElementById(`itServicesModule_${taskId}`) as HTMLDivElement;
    rootContainer = createRoot(root);

    const { store } = container;

    rootContainer.render(
        <StrictMode>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <StyledSnackbarProvider
                        action={snackbarId => Action(snackbarId)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        hideIconVariant
                    >
                        <ServiceFunctionsView
                            id={taskId}
                            formTypeId={formTypeId}
                            taskTypeId={taskTypeId}
                        />
                    </StyledSnackbarProvider>
                </ThemeProvider>
            </Provider>
        </StrictMode>,
    );
}

function closeOldTaskForm() {
    rootContainer?.unmount();
}

export default () => {
    const { events } = container;
    events.on(
        'itServices__serviceFunctionForm:initForOldTaskForm',
        initFormForOldTaskForm,
        false,
    );
    events.on(
        'itServices__serviceFunctionForm:closeOldTaskForm',
        closeOldTaskForm,
        false,
    );
    events.on(
        'router:before-route',
        closeOldTaskForm,
        false,
    );
};
