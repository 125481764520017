import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';

import { getMessage, getType } from '../utils/notificationsSelectors';

function NoticSnackbar() {
    const {
        success,
        error,
        info,
        warning,
    } = useEnqueueSnackbar();

    const message = useSelector(getMessage);
    const type = useSelector(getType);

    useEffect(() => {
        const noticObject = {
            msgOK: success,
            success,
            error,
            info,
            warning,
        };
        if (type) {
            noticObject[type](message);
        }
    }, [error, info, message, success, type, warning]);

    return (null);
}

export default NoticSnackbar;
