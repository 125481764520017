import React from 'react';
import { createPortal } from 'react-dom';

import { IIFieldsVisibleConfigReqeust } from 'CommonTypes/Forms/FormsFieldsConfig';

import useFieldsVisibleHook from 'Endpoints/Forms/useFieldsVisibleHook';

import RolesConfigTasksDataGrid from 'fsrc/RolesToRoutestates/components/RolesConfigTasksTab/RolesConfigTasksDataGrid';

import DescriptionTasks from './Description/DescriptionTasks';
import FilesTabCount from './FilesTable/FilesTabCount';
import FilesTabView from './FilesTable/FilesTabView';
import HistoryTabCount from './HistoryTable/HistoryTabCount';
import HistoryTableView from './HistoryTable/HistoryTableView';
import LinksTabCount from './LinksTable/LinksTabCount';
import LinksTableView from './LinksTable/LinksTableView';
import TimesheetTabCount from './TimesheetTable/TimesheetTabCount';
import TimesheetTableView from './TimesheetTable/TimesheetTableView';

type TData = {
    taskId: number;
} & IIFieldsVisibleConfigReqeust;

function TabsWrapper({ taskId, formTypeId, taskTypeId }: TData) {
    const { fieldsVisible } = useFieldsVisibleHook({ taskTypeId, formTypeId, fieldsIds: ['links__tab', 'history__tab', 'timesheet__tab', 'role-user__tab', 'description__tab', 'files__tab'] });

    return (
        <>
            { fieldsVisible?.links__tab && createPortal(<LinksTabCount taskId={taskId} />, document.getElementById(`task__linksTable-tab${taskId}_count`)!)}
            { fieldsVisible?.history__tab && createPortal(<HistoryTabCount taskId={taskId} />, document.getElementById(`task__history-tab-${taskId}_count`)!)}
            { fieldsVisible?.timesheet__tab && createPortal(<TimesheetTabCount taskId={taskId} />, document.getElementById(`task__timesheet-tab-${taskId}_count`)!)}
            { fieldsVisible?.files__tab && createPortal(<FilesTabCount taskId={taskId} />, document.getElementById(`sales-form__files-tab-${taskId}_count`)!) }

            { fieldsVisible?.links__tab && createPortal(<LinksTableView taskId={taskId} />, document.getElementById(`task__linksTable-tab${taskId}`)!)}
            { fieldsVisible?.history__tab && createPortal(<HistoryTableView taskId={taskId} />, document.getElementById(`task__history-tab-container${taskId}`)!)}
            { fieldsVisible?.timesheet__tab && createPortal(<TimesheetTableView taskId={taskId} />, document.getElementById(`task__timesheet-tab-container${taskId}`)!)}
            { fieldsVisible?.['role-user__tab'] && createPortal(<RolesConfigTasksDataGrid taskId={taskId} />, document.getElementById(`task__role-user-config-tab-container${taskId}`)!)}
            { fieldsVisible?.description__tab && createPortal(<DescriptionTasks taskId={taskId} />, document.getElementById(`task__description-tab-container${taskId}`)!)}
            { fieldsVisible?.files__tab && createPortal(<FilesTabView taskId={taskId} />, document.getElementById(`sales-form__files-tab-container${taskId}`)!)}
        </>
    );
}

export default TabsWrapper;
