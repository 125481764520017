import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
    Button, Icon,
} from '@mui/material';

import uploadTaskFiles from 'Domain/Tasks/QuickAddTaskForm/models/uploadTaskFiles';
import { IFile } from 'Domain/Tasks/QuickAddTaskForm/types/TFile';

import { tasksFilesApi } from 'Endpoints/Files/tasksFiles.api';

import { FilesUploadProps } from './types/FilesUpload';

function FilesUpload({ taskId }: FilesUploadProps) {
    const dispatch = useDispatch();

    const handleUploadFiles = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        const keysFileList = fileList ? Object.keys(fileList) : [];
        const setFiles = keysFileList.reduce((acc, keyFileList) => {
            const file: IFile = {
                file: fileList![(keyFileList as keyof typeof fileList)],
                fileName: fileList![(keyFileList as keyof typeof fileList)].name,
                fileDescription: '',
                id: fileList![(keyFileList as keyof typeof fileList)].name,
            };
            acc.push(file);
            return acc;
        }, [] as IFile[]);

        await uploadTaskFiles(setFiles, '', taskId);

        dispatch(tasksFilesApi.util.invalidateTags(['GetTasksFiles', 'GetTasksFilesCount']));
    }, [dispatch, taskId]);

    return (
        <Button
            variant="contained"
            component="label"
            startIcon={<Icon><i className="fa fa-upload" /></Icon>}
            id="upload-file"
        >
            Загрузить файл
            <input
                type="file"
                hidden
                multiple
                onChange={handleUploadFiles}
            />
        </Button>
    );
}

export default FilesUpload;
