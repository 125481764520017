import React, { useEffect } from 'react';

import { IIFieldsVisibleConfigReqeust } from 'CommonTypes/Forms/FormsFieldsConfig';

import { useGetFormsFieldsVisibleConfigQuery } from 'Endpoints/Forms/forms.api';

import container from 'Services/container';

function EmitFieldsVisibleConfig({ formTypeId, taskTypeId }: IIFieldsVisibleConfigReqeust) {
    const { events } = container;
    const {
        data: fieldsVisible,
        isFetching,
        isLoading,
    } = useGetFormsFieldsVisibleConfigQuery({ formTypeId, taskTypeId });

    useEffect(() => {
        if (!isLoading && !isFetching) {
            events.emit('task__formsVisibleConfig:isLoaded', fieldsVisible);
        }
    }, [events, fieldsVisible, isFetching, isLoading]);

    return (<>{ null }</>);
}

export default EmitFieldsVisibleConfig;
