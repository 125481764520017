/* eslint-disable react/jsx-props-no-spreading */
import React, {
    SyntheticEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
    useController, UseControllerReturn, useFormContext, useWatch,
} from 'react-hook-form';

import { Autocomplete, createFilterOptions, TextField } from '@mui/material';

import { ITagsWithProject } from 'CommonTypes/Tags/TagsWithProject';

import { useAddTagWithModuleTypeMutation, useGetTagsWithProjectQuery } from 'Endpoints/Tags/tags.api';
import { ITagsWithProjectFront } from 'Endpoints/Tags/types/ITagsWithProjectFront';

const filterSubprojects = createFilterOptions<ITagsWithProjectFront>();

function SubprojectSelect() {
    const [selectedTag, setSelectedTag] = useState<number | null>(null);

    const [addTag] = useAddTagWithModuleTypeMutation();

    const { data: tags = [], refetch } = useGetTagsWithProjectQuery();
    const { control } = useFormContext();

    const controller: UseControllerReturn = useController({
        name: 'subproject',
        control,
    });

    const selectedProjectId = useWatch({ control, name: 'project' })?.id || 0;

    const sortedTags = useMemo(() => [...tags].sort((a, b) => {
        const aBelongs = a.projectId.includes(selectedProjectId);
        const bBelongs = b.projectId.includes(selectedProjectId);

        if (aBelongs && !bBelongs) return -1;
        if (!aBelongs && bBelongs) return 1;

        return a.name.localeCompare(b.name);
    }), [tags, selectedProjectId]);

    const handleSubprojectChange = useCallback(async (
        e: SyntheticEvent,
        newValue: ITagsWithProject,
    ) => {
        if (typeof newValue === 'string' || newValue?.id === 0) {
            const name = typeof newValue === 'string' ? newValue : newValue.name.replace('Добавить "', '').replace('"', '').trim();
            const response = await addTag({ tagsName: name, moduleId: 3 }).unwrap();
            if (response?.success && response?.tagId) {
                setSelectedTag(Number(response.tagId));
                await refetch();
            }
            return true;
        }

        return controller.field.onChange(newValue);
    }, [addTag, controller.field, refetch]);

    useEffect(() => {
        if (selectedTag) {
            const newTag = sortedTags.find(tag => tag.id === selectedTag);
            if (newTag) {
                controller.field.onChange(newTag || null);
                if (selectedTag === newTag.id) {
                    setSelectedTag(null);
                }
            }
        }
    }, [controller.field, selectedTag, sortedTags]);

    return (
        <Autocomplete
            size="small"
            options={sortedTags}
            value={controller.field.value}
            blurOnSelect
            handleHomeEndKeys={false}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Подпроект"
                    error={!!controller.fieldState.error}
                    helperText={controller.fieldState.error?.message}
                    InputLabelProps={{ shrink: true }}
                />
            )}
            getOptionLabel={option => option.name}
            renderOption={(prop, option) => (
                <li {...prop} key={option.id} style={{ gap: '15px' }}>
                    {option.name}
                </li>
            )}
            onChange={handleSubprojectChange}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterOptions={(options, params) => {
                const filteredOptions = filterSubprojects(options, params);

                if (params.inputValue !== '' && !filteredOptions.length) {
                    filteredOptions.push({
                        id: 0,
                        name: `Добавить "${params.inputValue}"`,
                        module: 0,
                        projectId: [],
                    });
                }

                return filteredOptions;
            }}
        />
    );
}

export default SubprojectSelect;
