import { ICanUserAccessToTaskActionRequest, ICanUserAccessToTaskActionResponse } from 'CommonTypes/Permissions/CanUserAccessToTaskAction';

import emptySplitApi from 'Endpoints/emptySplitApi';

export const tasksPermissionsApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({

        canUserAccessToTask: build.query<
            ICanUserAccessToTaskActionResponse,
            ICanUserAccessToTaskActionRequest
        >({
            query: ({ taskId, permissions }) => ({
                url: `tasks/permissions?taskId=${taskId}${permissions.map(action => `&permissions[]=${action}`).join('')}`,
            }),
        }),

    }),
});

export const {
    useCanUserAccessToTaskQuery,
} = tasksPermissionsApi;
