import { IPriceFormMeasure, IPriceFormMeasuresResponse } from 'CommonTypes/PriceForm/PriceForm';

import emptySplitApi from 'Endpoints/emptySplitApi';

import { slimPath } from 'Config/path.js';

export const priceFormOldBackApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({

        getPriceMeasures: build.query<
            IPriceFormMeasure[],
            void
        >({
            query: () => ({
                url: `//${window.location.host}${slimPath}/priceList/getPriceMeasures`,
            }),
            transformResponse(response: IPriceFormMeasuresResponse) {
                const { options } = response;
                const result = options.map(row => (
                    { ...row, id: Number(row.id), value: row.title }
                ));
                return result;
            },
        }),

    }),
});

export const {
    useGetPriceMeasuresQuery,
} = priceFormOldBackApi;
