import {
    IPriceArticleCheckUniqRequest,
    IPriceArticleCheckUniqResponse,
    IPriceCreateRequest,
    IPriceCreateResponse,
    IPriceCurrency,
    IPriceDeleteRequest,
    IPriceDeleteResponse,
    IPricesByCategoryIdRequest,
    IPriceSearchByTextRequest,
    IPriceSimple,
    IPriceSimpleRequest,
    IPriceSimpleSet,
    IPricesVat,
    IPriceUpdateRequest,
    IPriceUpdateResponse,
} from 'CommonTypes/Prices/Prices';

import emptySplitApi from 'Endpoints/emptySplitApi';

import { IPricesAddToTaskRequest, IPricesAddToTaskResponse } from './types/PricesAddToTask';

export const pricesApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({

        getPriceById: build.query<
            IPriceSimple,
            IPriceSimpleRequest
        >({
            query: ({ priceId }) => ({
                url: `prices/price/${priceId}`,
            }),
            providesTags: (result, error, { priceId }) => [
                { type: 'GetPriceById', priceId },
            ],
        }),

        getPricesByCategoryId: build.query<
            IPriceSimpleSet[],
            IPricesByCategoryIdRequest
        >({
            query: params => ({
                url: 'prices/filter-by-category-id',
                params,
            }),
            providesTags: ['GetPricesByCategoryId'],
        }),

        searchPricesByText: build.query<
            IPriceSimpleSet[],
            IPriceSearchByTextRequest
        >({
            query: params => ({
                url: 'prices/filter-by-text',
                params,
            }),
            providesTags: ['SearchPricesByText'],
        }),

        deletePrice: build.mutation<
            IPriceDeleteResponse,
            IPriceDeleteRequest
        >({
            query: ({ priceId }) => ({
                url: `prices/${priceId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (response) => {
                if (response?.success === false && response.error === 'DELETE_RESTRICT') {
                    return []; // Не инвалидируем кэш, если удаление запрещено
                }
                return ['GetPricesByCategoryId', 'SearchPricesByText']; // Обычная инвалидация
            },
        }),

        getPricesCurrencies: build.query<
            IPriceCurrency[],
            void
        >({
            query: () => ({
                url: 'prices/currencies',
            }),
        }),

        getPricesVats: build.query<
            IPricesVat[],
            void
        >({
            query: () => ({
                url: 'prices/vats',
            }),
        }),

        createPrice: build.mutation<
            IPriceCreateResponse,
            IPriceCreateRequest
        >({
            query: body => ({
                url: 'prices',
                method: 'POST',
                body,
            }),
            invalidatesTags: (response) => {
                if (response?.success === false) {
                    return []; // Не инвалидируем кэш, если удаление запрещено
                }
                return ['GetPricesByCategoryId', 'SearchPricesByText']; // Обычная инвалидация
            },
        }),

        updatePrice: build.mutation<
            IPriceUpdateResponse,
            IPriceUpdateRequest
        >({
            query: ({ id, ...rest }) => ({
                url: `prices/${id}`,
                method: 'PUT',
                body: rest,
            }),
            invalidatesTags: (response, error, { id }) => {
                if (response?.success === false) {
                    return []; // Не инвалидируем кэш, если удаление запрещено
                }
                return [
                    { type: 'GetPriceById', priceId: id },
                    'GetPricesByCategoryId',
                    'SearchPricesByText',
                ]; // Обычная инвалидация
            },
        }),

        checkArticleUniq: build.query<
            IPriceArticleCheckUniqResponse,
            IPriceArticleCheckUniqRequest
        >({
            query: params => ({
                url: 'prices/articles/is-uniq',
                params,
            }),
            // query: ({ article, priceId = 0 }) => {
            //     const encodedArticle = encodeURIComponent(article);
            //     const url = `prices/articles/is-uniq?article=${encodedArticle}
            // ${priceId ? `&priceId=${priceId}` : ''}`;
            //     return url;
            // },
            keepUnusedDataFor: 0, // Убираем кэш сразу после использования
        }),

        pricesAddToTask: build.mutation<
            IPricesAddToTaskResponse,
            IPricesAddToTaskRequest
        >({
            query({ taskId, prices }) {
                const bodyFormData = new FormData();
                bodyFormData.append('id_task', String(taskId));

                prices.forEach((price, index) => {
                    bodyFormData.append(`prices[${index}][id]`, String(price.id));
                    bodyFormData.append(`prices[${index}][count]`, String(price.count));
                    bodyFormData.append(`prices[${index}][project]`, String(price.projectId));
                });

                return {
                    url: `//${window.location.host}/ajax/task_price_add.php`,
                    method: 'POST',
                    body: bodyFormData,
                };
            },
        }),

    }),
});

export const {
    useGetPriceByIdQuery,
    useGetPricesByCategoryIdQuery,
    useSearchPricesByTextQuery,
    useDeletePriceMutation,
    useGetPricesCurrenciesQuery,
    useGetPricesVatsQuery,
    useCreatePriceMutation,
    useUpdatePriceMutation,
    useLazyCheckArticleUniqQuery,
    usePricesAddToTaskMutation,
} = pricesApi;
