/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { useGetContragentsSimpleSetQuery } from 'Endpoints/Contragents/contragents.api';
import { useGetPriceMeasuresQuery } from 'Endpoints/PriceForm/priceFormOldBack.api';
import {
    useCreatePriceMutation,
    useGetPriceByIdQuery,
    useGetPricesCurrenciesQuery,
    useGetPricesVatsQuery,
    useLazyCheckArticleUniqQuery,
    useUpdatePriceMutation,
} from 'Endpoints/Prices/prices.api';
import { useGetPricesCategoriesQuery } from 'Endpoints/Prices/pricesCategories.api';

import { Modal } from 'Ui/Modal';

import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';

import { getSelectedCategoryId } from '../PriceListTreeTable/utils/priceListTreeTableSelectors';

import parseDefaultFormData from './models/parseDefaultFormData';
import parseFormDataToEndPoint from './models/parseFormDataToEndPoint';
import PriceForm from './PriceForm';
import PriceWindowFooter from './PriceWindowFooter';
import { IPriceFormState, IPriceWindowProps } from './types/PriceForm';

function PriceWindow({ closeCallback, priceIdForEdit = 0, priceIdForCopy = 0 }: IPriceWindowProps) {
    const [addPrice] = useCreatePriceMutation();
    const [updatePrice] = useUpdatePriceMutation();
    const [checkArticleUniqueness] = useLazyCheckArticleUniqQuery();
    const { error } = useEnqueueSnackbar();

    const selectedCategoryId = useSelector(getSelectedCategoryId);

    const { data: formDataForEditCopy } = useGetPriceByIdQuery(
        { priceId: priceIdForEdit || priceIdForCopy },
        { skip: !priceIdForEdit && !priceIdForCopy },
    );

    const title = useMemo(() => {
        const finish = ' прайслиста';
        if (priceIdForEdit > 0) return `Редактирование ${finish}`;
        if (priceIdForCopy > 0) return `Копирование ${finish}`;
        return `Создание ${finish}`;
    }, [priceIdForCopy, priceIdForEdit]);

    const { data: categories = [] } = useGetPricesCategoriesQuery();
    const { data: measuresOptions = [] } = useGetPriceMeasuresQuery();
    const { data: contragents = [] } = useGetContragentsSimpleSetQuery();
    const { data: pricesCurrencies = [] } = useGetPricesCurrenciesQuery();
    const { data: pricesVats = [] } = useGetPricesVatsQuery();

    const form = useForm<IPriceFormState>({
        defaultValues: {
            name: '',
            shortname: '',
            article: '',
            measure: null,
            service: false,
            provider: null,
            manufacturer: null,
            category: null,

            purchasePrice: 0,
            purchaseCurrency: null,
            purchaseVat: null,

            extraChargeType: null,
            extraChargeValue: null,

            sellingPrice: 0,
            sellingCurrency: null,
            sellingVat: null,

            url: '',
            note: '',
        },
    });

    const handleWindowClose = useCallback(() => {
        closeCallback();
    }, [closeCallback]);

    const onSubmit = useCallback(async () => {
        const formData = form.getValues();
        const dataForSend = parseFormDataToEndPoint(formData);

        const { article = '' } = dataForSend;
        if (article) {
            const { isUniq } = await checkArticleUniqueness(
                { article, priceId: priceIdForEdit },
            ).unwrap();
            if (!isUniq) {
                form.setError('article', { type: 'manual', message: 'Артикул не уникален!' });
                return;
            }
        }

        if (priceIdForEdit) {
            const updateData = {
                id: priceIdForEdit,
                ...dataForSend,
            };
            updatePrice(updateData).unwrap().then((response) => {
                if (!response?.success) {
                    error(response?.error || 'Во время сохранения произошла ошибка');
                }
            });
        } else {
            addPrice(dataForSend).unwrap().then((response) => {
                if (!response?.success) {
                    error(response?.error || 'Во время сохранения произошла ошибка');
                }
            });
        }

        closeCallback();
    }, [addPrice, checkArticleUniqueness, closeCallback, error, form, priceIdForEdit, updatePrice]);

    useEffect(() => {
        if (!priceIdForEdit) {
            const formData = form.getValues();
            formData.category = categories.find(cat => cat.id === selectedCategoryId) || null;

            form.reset(formData);
        }
    }, [categories, form, priceIdForEdit, selectedCategoryId]);

    useEffect(() => {
        if (
            (priceIdForEdit || priceIdForCopy)
            && formDataForEditCopy
            && categories
            && contragents
            && form
            && measuresOptions
            && pricesCurrencies
            && pricesVats
        ) {
            const defaultFormData = parseDefaultFormData({
                priceData: formDataForEditCopy,
                categories,
                measures: measuresOptions,
                contragents,
                pricesCurrencies,
                pricesVats,
            });
            form.reset(defaultFormData);
        }
    }, [priceIdForEdit,
        categories,
        contragents,
        form,
        measuresOptions,
        pricesCurrencies,
        pricesVats,
        priceIdForCopy,
        formDataForEditCopy,
    ]);

    useEffect(() => {
        if (!priceIdForEdit
            && !priceIdForCopy
            && measuresOptions
            && pricesCurrencies
            && pricesVats
        ) {
            const formData = form.getValues();
            formData.measure = measuresOptions.find(measure => measure.id === 746) || null;
            const currency = pricesCurrencies.find(row => row.id === 643) || null;
            formData.sellingCurrency = currency;
            formData.purchaseCurrency = currency;
            const vat = pricesVats.find(row => row.id === 1) || null;
            formData.sellingVat = vat;
            formData.purchaseVat = vat;

            form.reset(formData);
        }
    }, [
        form,
        measuresOptions,
        priceIdForCopy,
        priceIdForEdit,
        pricesCurrencies,
        pricesVats,
    ]);

    return (
        <Modal
            title={title}
            open
            width={1000}
            height={800}
            onClose={handleWindowClose}
            footer={(
                <PriceWindowFooter
                    handleSubmitClick={form.handleSubmit(onSubmit)}
                    handleCloseButtonClick={handleWindowClose}
                />
            )}
        >
            <FormProvider {...form}>
                <PriceForm
                    categories={categories}
                    measures={measuresOptions}
                    contragents={contragents}
                    pricesCurrencies={pricesCurrencies}
                    pricesVats={pricesVats}
                />
            </FormProvider>
        </Modal>
    );
}

export default PriceWindow;
