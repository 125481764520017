/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { INotificationState } from '../types/SliceState';

const initialState: INotificationState = {
    message: '',
    type: null,
};

const notificationsSlice = createSlice({
    name: 'NOTIFICATIONS_SLICE',
    initialState,
    reducers: {
        setMessage: (state, action) => {
            state.message = action.payload.message;
            state.type = action.payload.type;
        },
    },
});

export const {
    setMessage,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
