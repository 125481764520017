import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Paper } from '@mui/material';

import { Resizable } from 're-resizable';

import PriceWindow from '../../PriceWindowForm/PriceWindow';
import {
    getAddCategoryDialogIsOpen,
    getCategoryIdForEdit,
    getCreatePrice,
    getPriceIdForCopy,
    getPriceIdForEdit,
} from '../utils/priceListTreeTableSelectors';
import { closePriceWindow } from '../utils/priceListTreeTableSlice';

import AddCategoryModal from './AddCategoryModal';
import PriceListDataGridComponent from './PriceListDataGridComponent';
import PriceListToolbar from './PriceListToolbar';
import PriceListTreeComponent from './PriceListTreeComponent';

function PriceListTreeTableView() {
    const dispatch = useDispatch();

    const priceIdForEdit = useSelector(getPriceIdForEdit);
    const priceIdForCopy = useSelector(getPriceIdForCopy);
    const createPrice = useSelector(getCreatePrice);
    const categoryIdForEdit = useSelector(getCategoryIdForEdit);
    const addCategoryDialogIsOpen = useSelector(getAddCategoryDialogIsOpen);

    const priceWindowOpen = useMemo(
        () => Boolean(priceIdForEdit) || Boolean(priceIdForCopy) || createPrice,
        [createPrice, priceIdForCopy, priceIdForEdit],
    );

    const handlePriceWindowClose = useCallback(() => {
        dispatch(closePriceWindow());
    }, [dispatch]);

    return (
        <>
            <PriceListToolbar />
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                }}
            >
                <Resizable
                    defaultSize={{
                        width: '25%',
                        height: 'inherit%',
                    }}
                    minWidth={250}
                    style={{
                        borderRight: '1px solid #ddd',
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <PriceListTreeComponent />
                </Resizable>

                <Paper
                    sx={{
                        width: '100%',
                        minWidth: '1px',
                    }}
                >
                    <PriceListDataGridComponent />
                </Paper>
            </div>
            {
                priceWindowOpen
            && (
                <PriceWindow
                    closeCallback={handlePriceWindowClose}
                    priceIdForEdit={priceIdForEdit || 0}
                    priceIdForCopy={priceIdForCopy || 0}
                />
            )
            }
            {
                addCategoryDialogIsOpen
                && <AddCategoryModal categoryIdForEdit={categoryIdForEdit || 0} />
            }
        </>
    );
}

export default PriceListTreeTableView;
