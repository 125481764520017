import React from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Delete from '@mui/icons-material/Delete';
import { GridActionsCellItem, GridActionsColDef, GridRowParams } from '@mui/x-data-grid';

import { IColumnsPriceListDataGridProps } from '../types/columnsPriceListHook';

import priceListDataGridColumns from './priceListDataGridColumns';

function useColumnsPriceListDataGrid({
    deletePriceCallback,
    copyPriceCallback,
}: IColumnsPriceListDataGridProps) {
    const actions: GridActionsColDef = {
        field: 'Кнопки',
        type: 'actions',
        width: 60,
        getActions: (params: GridRowParams) => [
            <GridActionsCellItem
                icon={<ContentCopyIcon />}
                label="Скопировать"
                onClick={() => copyPriceCallback(Number(params?.id) || 0)}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                showInMenu={undefined}
            />,
            <GridActionsCellItem
                icon={<Delete />}
                label="Удалить"
                onClick={() => deletePriceCallback(Number(params?.id) || 0)}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                showInMenu={undefined}
            />,
        ],
    };

    const columnsUpdated = [
        ...priceListDataGridColumns,
        actions,
    ];

    return columnsUpdated;
}

export default useColumnsPriceListDataGrid;
