import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useGetContractsByContragentFirmSimpleSetQuery } from 'Endpoints/Contracts/contracts.api';

import { Autocomplete } from 'Ui/Form/Autocomplete';

function ContractSelect() {
    const form = useFormContext();

    const selectedContragentId = useWatch({ control: form.control, name: 'contragent' })?.id ?? null;
    const selectedFirmId = useWatch({ control: form.control, name: 'firm' })?.id ?? null;

    const { data: contracts = [] } = useGetContractsByContragentFirmSimpleSetQuery(
        { firmId: selectedFirmId, contragentId: selectedContragentId },
        {
            skip: selectedFirmId === null || selectedContragentId === null,
        },
    );

    return (
        <Autocomplete
            name="contract"
            label="Договор"
            options={contracts}
            blurOnSelect
            inputValue="subject"
            listValue="subject"
        />
    );
}

export default ContractSelect;
