/* eslint-disable max-len */
import { configureStore, Reducer } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query/react';
import { AnyAction, combineReducers } from 'redux';

import emptySplitApi from 'Endpoints/emptySplitApi';

import notificationsReducer from 'fsrc/Notifications/utils/notificationsReducer';
import rtkQueryErrorLogger from 'Utils/rtkQueryErrorLogger';

import baseReducer from './baseSlice';
import { TAsyncReducers } from './TAsyncReducers';
import triggerActionMiddleware from './triggerActionMiddleware';
import userReducer from './userSlice';

const asyncReducers: TAsyncReducers = {};

const staticReducers = {
    reducerUser: userReducer,
    reducerBase: baseReducer,
    notificationReducer: notificationsReducer,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
};

const store = configureStore({
    reducer: {
        ...staticReducers,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware()
        .concat([
            emptySplitApi.middleware,
            triggerActionMiddleware,
            rtkQueryErrorLogger,
        ]),
});

setupListeners(store.dispatch);

export function injectReducer<T>(name: string, reducer: Reducer<T, AnyAction>) {
    asyncReducers[name] = reducer;

    return store.replaceReducer(
        combineReducers({
            ...staticReducers,
            ...asyncReducers,
        }),
    );
}

export type RootState = ReturnType<typeof store.getState>

export default store;
