/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
    useAddPricesCategoryMutation,
    useGetPricesCategoriesQuery,
    useGetPricesCategoryQuery,
    useUpdatePricesCategoryMutation,
} from 'Endpoints/Prices/pricesCategories.api';
import { useGetSaleProjectsSimpleSetQuery } from 'Endpoints/Projects/projects.api';
import { useGetUsersActiveSimpleSetQuery } from 'Endpoints/Users/users.api';

import { Modal } from 'Ui/Modal';

import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';

import parseFormDataCategory from '../models/parseFormDataCategory';
import { ICategoryFormState, ICategoryModalWindowProps } from '../types/CategoryForm';
import { getSelectedCategoryId } from '../utils/priceListTreeTableSelectors';
import { setCategoryDialogIsOpen, setCategoryIdForEdit } from '../utils/priceListTreeTableSlice';

import AddCategoryModalFooter from './AddCategoryModalFooter';
import CategoryForm from './CategoryForm';

function AddCategoryModal({ categoryIdForEdit = 0 }: ICategoryModalWindowProps) {
    const dispatch = useDispatch();
    const [addCategory] = useAddPricesCategoryMutation();
    const [updateCategory] = useUpdatePricesCategoryMutation();

    const { data: users = [] } = useGetUsersActiveSimpleSetQuery();
    const { data: projects = [] } = useGetSaleProjectsSimpleSetQuery();
    const { data: categories = [] } = useGetPricesCategoriesQuery();
    const { data: categoryInfo, isSuccess: categoryInfoLoadedSuccess } = useGetPricesCategoryQuery(
        { categoryId: categoryIdForEdit },
        {
            skip: !categoryIdForEdit,
        },
    );

    const categoriesWithRoot = useMemo(() => [
        {
            parentId: 0,
            id: 0,
            value: 'В КОРЕНЬ',
            catOrder: 0,
        },
        ...categories || [],
    ], [categories]);

    const { error } = useEnqueueSnackbar();

    const selectedCategoryId = useSelector(getSelectedCategoryId);

    const form = useForm<ICategoryFormState>({
        defaultValues: {
            name: '',
            project: null,
            parentCategory: null,
            users: [],
        },
    });

    const handleCloseButtonClick = useCallback(() => {
        dispatch(setCategoryIdForEdit(null));
        dispatch(setCategoryDialogIsOpen(false));
    }, [dispatch]);

    const onSubmit = useCallback(() => {
        const formData = form.getValues();
        const dataForSend = parseFormDataCategory(formData);
        handleCloseButtonClick();
        if (categoryIdForEdit) {
            return updateCategory({
                categoryId: categoryIdForEdit,
                ...dataForSend,
                value: dataForSend.name,
                users: dataForSend.users,
            }).unwrap().then((response) => {
                if (!response?.success) {
                    error('Во время обновления Категории на сервере возникла ошибка');
                }
            });
        }
        return addCategory(dataForSend).unwrap()
            .then((response) => {
                if (!response?.success) {
                    error('Во время добавления Категории на сервере возникла ошибка');
                }
            });
    }, [
        addCategory,
        categoryIdForEdit,
        error,
        form,
        handleCloseButtonClick,
        updateCategory,
    ]);

    useEffect(() => {
        if (categoriesWithRoot?.length && !categoryIdForEdit) {
            const formValues = form.getValues();
            const newParentCategory = categoriesWithRoot.find(
                cat => cat.id === (selectedCategoryId || 0),
            ) || null;

            if (formValues.parentCategory?.id !== newParentCategory?.id) {
                form.reset({
                    ...formValues,
                    parentCategory: newParentCategory,
                });
            }
        }

        if (categoryIdForEdit && categoryInfoLoadedSuccess) {
            const formValues = form.getValues();
            formValues.name = categoryInfo.value;
            formValues.parentCategory = categoriesWithRoot.find(
                cat => cat.id === (categoryInfo.parentId || 0),
            ) || null;
            formValues.project = projects.find(
                project => project.id === categoryInfo.projectId,
            ) || null;
            formValues.users = users.filter(
                user => categoryInfo.users?.includes(user.id),
            );
            form.reset(formValues);
        }
    }, [
        categoriesWithRoot,
        categoryIdForEdit,
        categoryInfo?.parentId,
        categoryInfo?.projectId,
        categoryInfo?.users,
        categoryInfo?.value,
        categoryInfoLoadedSuccess,
        form,
        projects,
        selectedCategoryId,
        users,
    ]);

    return (
        <Modal
            title="Введите наименование Категории"
            open
            width={600}
            height={500}
            onClose={handleCloseButtonClick}
            footer={(
                <AddCategoryModalFooter
                    handleCloseButtonClick={handleCloseButtonClick}
                    handleSubmitClick={form.handleSubmit(onSubmit)}
                />
            )}
        >
            <FormProvider {...form}>
                <CategoryForm
                    users={users}
                    projects={projects}
                    categories={categoriesWithRoot}
                />
            </FormProvider>
        </Modal>
    );
}

export default AddCategoryModal;
