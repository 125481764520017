import React from 'react';

import { Grid } from '@mui/material';

import FilesUpload from './FilesUpload';
import { IFilesTableToolbarProps } from './types/FilesTable';

function FilesTableToolbar({ taskId }: IFilesTableToolbarProps) {
    return (
        <Grid container>
            <Grid item>
                <FilesUpload taskId={taskId} />
            </Grid>
        </Grid>
    );
}

export default FilesTableToolbar;
