import { IContractsRequest, IContractsSimpleSet } from 'CommonTypes/Contracts/Contracts';

import emptySplitApi from 'Endpoints/emptySplitApi';

export const contractsApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({

        getContractsByContragentFirmSimpleSet: build.query<
            IContractsSimpleSet[],
            IContractsRequest
        >({
            query: params => ({
                url: 'contracts/by-contragent-firm',
                params,
            }),
        }),

    }),
});

export const {
    useGetContractsByContragentFirmSimpleSetQuery,
} = contractsApi;
