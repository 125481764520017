import {
    ITagAddRequest, ITagAddResponse, ITagSetWithRouteSort, ITagSimpleSet, ITagsWithRouteSortRequest,
} from 'CommonTypes/Tags/TagsSimpleSet';
import { ITagsWithProject } from 'CommonTypes/Tags/TagsWithProject';

import emptySplitApi from 'Endpoints/emptySplitApi';

import { ITagsWithProjectFront } from './types/ITagsWithProjectFront';

export const tagsApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({

        getTagsSimpleSet: build.query<
            ITagSimpleSet[],
            void
        >({
            query: () => ({
                url: 'tags',
            }),
        }),

        getTagsWithRouteSort: build.query<
            ITagSetWithRouteSort[],
            ITagsWithRouteSortRequest
        >({
            query: params => ({
                url: 'tags/route-sort',
                params,
            }),
        }),

        getTagsWithProject: build.query<
            ITagsWithProjectFront[],
            void
        >({
            query: () => ({
                url: 'tags/with-project',
            }),
            transformResponse: (
                response: ITagsWithProject[],
            ) => response.map(
                tag => ({ ...tag, projectId: tag.projectId.split(',').filter(el => el).map(Number) }),
            ),
            providesTags: ['GetTagsWithProject'],
        }),

        addTagWithModuleType: build.mutation<
            ITagAddResponse,
            ITagAddRequest
        >({
            query: body => ({
                url: 'tags',
                method: 'POST',
                body,
            }),
            invalidatesTags(response) {
                if (response?.success && response?.tagId) {
                    return ['GetTagsWithProject'];
                }
                return [];
            },
        }),

    }),
});

export const {
    useGetTagsSimpleSetQuery,
    useGetTagsWithRouteSortQuery,
    useGetTagsWithProjectQuery,
    useAddTagWithModuleTypeMutation,
} = tagsApi;
