/* eslint-disable camelcase */
import dayjs from 'dayjs';

import defaultTasksConfig from 'Config/default/defaultTasksConfig';

import createInboxMessage from '../services/createInboxMessage';
import { ITaskData } from '../types/ITaskData';
import { TTypeTask } from '../types/TTypeTask';

import getTaskDescription from './getTaskDescription';
import uploadTaskFiles from './uploadTaskFiles';

export default async (
    type: TTypeTask,
    data: ITaskData,
    eventId?: string,
) => {
    const {
        contact, phone, deadline, taskName, project, assignee, serviceClasse,
        taskTypes, filesUploader, observers, role, bug, planId, parentId, parentTaskId, uid, owner,
        routeId, stateName, submissionType, department, services, functions, orgstructure,
        doctype, indate, innumber, intotal, id_checklist, firm, contragent, contract,
        subproject, linkedTaskId,
    } = data;

    const description = getTaskDescription(data, type);

    let newUid = uid;
    if (eventId !== 'template' && !uid && doctype && doctype.id > 0) {
        const response = await createInboxMessage({
            type: doctype.id,
            contactId: contact!.id,
            name: taskName,
            desc: description,
            docDate: indate || dayjs().endOf('day'),
            innumber: innumber || '',
            total: intotal || 0,
        });

        if (response && response.success && response.uid) {
            newUid = response.uid;
        }
    }

    const assigneeId = assignee ? assignee.id : null;

    const isSalesProject = type !== 'issue' && project?.objectClassId === 5;

    const baseDataTask = {
        name: taskName,
        contact: contact!.id,
        phone,
        observers: observers.map(observer => observer.id),
        serviceClassId: serviceClasse!.id,
        deadline: deadline!.unix(),
        project: type === 'issue' ? defaultTasksConfig.issue.project : project!.id,
        idSubProject: subproject?.id || 0,
        taskTypeId: taskTypes!.id,
        assignee: type === 'issue' ? defaultTasksConfig.issue.assignee.id : assigneeId,
        role: role ? role.id : null,
        desc: description,
        attach: await uploadTaskFiles(filesUploader),
        bug,
        planId,
        id_parent: parentTaskId?.id ? parentTaskId.id : parentId,
        uid: newUid ?? '',
        owner,
        routeId,
        stateName,
        submissionType,
        department,
        it_service: services?.id ?? null,
        functionId: functions?.length ? functions.map(func => func.id) : null,
        subdivisionId: orgstructure?.id ?? null,
        id_checklist,
        firm: isSalesProject ? firm?.id || 0 : 0,
        contragent: isSalesProject ? contragent?.id || 0 : 0,
        contract: isSalesProject ? contract?.id || 0 : 0,
        linkedTaskId,
    };

    if (newUid || eventId === 'template') {
        const extendedData = {
            ...baseDataTask,
            doctype: doctype?.id || 0,
            innumber: innumber || '',
            indate: indate?.unix() || 0,
            intotal: intotal || 0,
        };

        return extendedData;
    }

    return baseDataTask;
};
