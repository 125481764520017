import { createSlice } from '@reduxjs/toolkit';

import { IPriceListTreeTableState } from '../types/PriceListTreeTableSlice';

const initialState: IPriceListTreeTableState = {
    selectedCategory: null,
    searchText: null,
    priceIdForDelete: null,
    priceIdForEdit: null,
    priceIdForCopy: null,
    createPrice: false,
    categoryIdForEdit: null,
    categoryDialogIsOpen: false,
    selectionMode: false,
    selectedPrices: [],
};

export const priceListTreeTableSlice = createSlice({
    name: 'PRICE_LIST_TREE_TABLE',
    initialState,
    reducers: {
        setSelectedCategory(state, action) {
            state.selectedCategory = action?.payload || null;
        },
        setSearchText(state, action) {
            state.searchText = action?.payload || null;
        },
        setPriceIdForDelete(state, action) {
            state.priceIdForDelete = action?.payload || null;
        },
        setPriceIdForEdit(state, action) {
            state.priceIdForEdit = action?.payload || null;
        },
        setPriceIdForCopy(state, action) {
            state.priceIdForCopy = action?.payload || null;
        },
        setCreatePrice(state, action) {
            state.createPrice = action?.payload || false;
        },
        closePriceWindow(state) {
            state.priceIdForEdit = null;
            state.priceIdForCopy = null;
            state.createPrice = false;
        },
        setCategoryIdForEdit(state, action) {
            state.categoryIdForEdit = action?.payload || null;
        },
        setCategoryDialogIsOpen(state, action) {
            state.categoryDialogIsOpen = action?.payload || false;
        },
        setSelectionMode(state, action) {
            state.selectionMode = action?.payload || false;
            state.selectedPrices = [];
            state.selectedCategory = null;
        },
        setSelectedPrices(state, action) {
            state.selectedPrices = action?.payload || [];
        },
    },
});

export const {
    setSelectedCategory,
    setSearchText,
    setPriceIdForDelete,
    setPriceIdForEdit,
    setPriceIdForCopy,
    setCreatePrice,
    closePriceWindow,
    setCategoryIdForEdit,
    setCategoryDialogIsOpen,
    setSelectionMode,
    setSelectedPrices,
} = priceListTreeTableSlice.actions;

export default priceListTreeTableSlice.reducer;
