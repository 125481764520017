import React, {
    useCallback, useEffect, useMemo, useRef,
    useState,
} from 'react';

import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { useGetTasksDescriptionQuery, useUpdateTasksDescriptionMutation } from 'Endpoints/Tasks/tasksDescription.api';
import { useCanUserAccessToTaskQuery } from 'Endpoints/Tasks/tasksPermissions.api';

import Editor from 'Ui/Editor';
import { IClassicEditor } from 'Ui/Editor/types/ICKEditorClassic';

import { IDescriptionProps } from 'fsrc/Task/types/DescriptionTypes';
import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';

function DescriptionTasks({ taskId }: IDescriptionProps) {
    const [isChanged, setIsChanged] = useState(false);
    const descriptionRef = useRef<IClassicEditor | null>(null);
    const { success, error } = useEnqueueSnackbar();

    const { data: descriptionInfo } = useGetTasksDescriptionQuery({ taskId });
    const { data: canAccessToTask } = useCanUserAccessToTaskQuery({ taskId, permissions: ['write'] });

    const canEdit = useMemo(() => canAccessToTask?.can || false, [canAccessToTask?.can]);

    const [updateTasksDescription] = useUpdateTasksDescriptionMutation();

    const ckEditorId = useMemo(() => `ckEditorId${taskId}`, [taskId]);

    const handleButtonEditClick = useCallback(() => {
        descriptionRef.current?.disableReadOnlyMode(ckEditorId);
    }, [ckEditorId]);

    const handleButtonUpdateClick = useCallback(() => {
        const description = descriptionRef?.current?.getData();
        const data = {
            taskId,
            description: description || '',
        };
        updateTasksDescription(data).unwrap().then((response) => {
            if (response?.success) {
                success('Описание успешно обновлено');
                descriptionRef.current?.enableReadOnlyMode(ckEditorId);
                setIsChanged(false);
            } else {
                error('Во время обновления Описания произошла ошибка');
            }
        });
    }, [ckEditorId, error, success, taskId, updateTasksDescription]);

    useEffect(() => {
        descriptionRef?.current?.setData(descriptionInfo?.description || '');
        descriptionRef.current?.enableReadOnlyMode(ckEditorId);
    }, [ckEditorId, descriptionInfo?.description, taskId]);

    return (
        <>
            <Grid container spacing={1}>
                { canEdit
                && (
                    <Grid item>
                        <Tooltip title="Редактировать">
                            <IconButton
                                aria-label="edit"
                                onClick={handleButtonEditClick}
                            >
                                <Icon><i className="fa fa-pencil" /></Icon>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}
                { canEdit && isChanged
                && (
                    <Grid item>
                        <Tooltip title="Сохранить">
                            <IconButton
                                aria-label="save"
                                onClick={handleButtonUpdateClick}
                            >
                                <Icon><i className="fa fa-save" /></Icon>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
            <Editor
                onReady={(editor) => {
                    descriptionRef.current = editor;
                    setTimeout(() => descriptionRef.current?.enableReadOnlyMode(ckEditorId));
                }}
                onChange={() => setIsChanged(true)}
                value={descriptionInfo?.description || ''}
            />
        </>
    );
}

export default DescriptionTasks;
