import { GridColDef } from '@mui/x-data-grid';

import { IPriceSimpleSet } from 'CommonTypes/Prices/Prices';

const priceListDataGridColumns: GridColDef<IPriceSimpleSet>[] = [
    {
        field: 'categoryId',
        headerName: 'ID Категории',
        type: 'number',
        width: 90,
    },
    {
        field: 'id',
        headerName: 'ID',
        type: 'number',
        width: 90,
    },
    {
        field: 'name',
        headerName: 'Наименование',
        flex: 1,
    },
    {
        field: 'article',
        headerName: 'Артикул',
        width: 150,
    },
    {
        field: 'saleProjectId',
        headerName: 'ID Проекта',
        width: 150,
    },
    {
        field: 'projectName',
        headerName: 'Проект',
        width: 200,
    },
    {
        field: 'measureId',
        headerName: 'ID ед.изм.',
        type: 'number',
        width: 90,
    },
    {
        field: 'unit',
        headerName: 'Ед.изм.',
        width: 90,
    },
    {
        field: 'sellingPrice',
        headerName: 'Продажи цена',
        type: 'number',
        width: 150,
    },
    {
        field: 'purchasePrice',
        headerName: 'Закупки цена',
        type: 'number',
        width: 150,
    },
];

export default priceListDataGridColumns;
