import { IDefaultFormDataParserProps, IPriceFormState } from '../types/PriceForm';

export default ({
    priceData,
    measures,
    contragents,
    categories,
    pricesCurrencies,
    pricesVats,
}: IDefaultFormDataParserProps): IPriceFormState => ({
    name: priceData.name,
    shortname: priceData.shortname,
    article: priceData.article || '',
    measure: measures.find(record => +(record.id) === priceData.measureId) || null,
    service: Boolean(priceData.serviceFlag),
    provider: contragents.find(record => record.id === priceData.providerId) || null,
    manufacturer: contragents.find(record => record.id === priceData.manufacturerId) || null,
    category: categories.find(record => record.id === priceData.categoryId) || null,

    purchasePrice: priceData.purchasePrice,
    purchaseCurrency: pricesCurrencies.find(
        record => record.id === priceData.purchaseCurrency,
    ) || null,
    purchaseVat: pricesVats.find(record => record.id === priceData.purchaseVat) || null,

    extraChargeType: 0,
    extraChargeValue: 0,

    sellingPrice: priceData.sellingPrice,
    sellingCurrency: pricesCurrencies.find(
        record => record.id === priceData.sellingCurrency,
    ) || null,
    sellingVat: pricesVats.find(record => record.id === priceData.sellingVat) || null,

    url: priceData.url || '',
    note: priceData.note || '',
});
