import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { DataGrid } from '@mui/x-data-grid';

import AttachViewer from 'Domain/AttachViewer/AttachViewer';
import { CustomSlide } from 'Domain/AttachViewer/types/AttachViewer';

import { useDeleteFileFromTaskMutation, useGetTasksFilesQuery, useUpdateFilesDescriptionMutation } from 'Endpoints/Files/tasksFiles.api';

import { ConfirmDeleteModal } from 'Ui/Modal';

import { isFilesTabActivated } from 'fsrc/Task/store/activeTabsFromTasksFormSelector';
import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';

import FilesTableToolbar from './FilesTableToolbar';
import useColumnsFilesTable from './models/useColumnsFilesTable';
import { IFilesTableDataGridProps } from './types/FilesTable';

function FilesTableDataGrid({ taskId }: IFilesTableDataGridProps) {
    const [viewerIndex, setViewerIndex] = useState<number | null>(null);

    const { error } = useEnqueueSnackbar();

    const isTabActivated = useSelector(isFilesTabActivated);
    const [fileIdToDelete, setFileIdToDelete] = useState<number | null>(null);

    const [deleteFile] = useDeleteFileFromTaskMutation();
    const [updateDescription] = useUpdateFilesDescriptionMutation();

    const { data = [] } = useGetTasksFilesQuery(
        { taskId },
        {
            skip: !isTabActivated,
        },
    );

    const handleDeleteButtonClick = useCallback((fileId: number) => setFileIdToDelete(fileId), []);

    const handleModalDeleteClose = useCallback((result: boolean) => {
        if (result && fileIdToDelete && taskId) {
            deleteFile({ id_file: fileIdToDelete, id_task: taskId }).unwrap().then((response) => {
                if (!response?.success) {
                    error(response?.error || 'На сервере возникла ошибка во время удаления файла');
                }
            });
        }
        setFileIdToDelete(null);
    }, [deleteFile, error, fileIdToDelete, taskId]);

    const columns = useColumnsFilesTable({ deleteCallback: handleDeleteButtonClick });

    const slides: CustomSlide[] = useMemo(() => {
        const regexPdf = /\.pdf$/;
        const imageExtensionsRegex = /\.(jpeg|jpg|png|gif|bmp|webp|tif|tiff|svg|ico)$/i;
        const result: CustomSlide[] = data.map(row => ({
            fileId: row.id,
            src: `/${row.folder}${row.sname}`,
            sname: row.sname,
            title: row.name,
            name: row.name,
            isPdf: regexPdf.test(row.sname),
            onlyDownload: !(regexPdf.test(row.sname) || imageExtensionsRegex.test(row.sname)),
            description: row.description || '',
        }));

        return result;
    }, [data]);

    const handleAttachViewerClose = useCallback(() => setViewerIndex(null), []);

    return (
        <>
            <FilesTableToolbar taskId={taskId} />
            <DataGrid
                rows={data}
                columns={columns}
                onCellClick={(params) => {
                    if (params.field === 'name') {
                        const rowIndex = slides.findIndex(slide => slide.fileId === params.id);
                        setViewerIndex(rowIndex);
                    }
                }}
                onCellEditCommit={(params) => {
                    const { field = '', id = 0, value = '' } = params;
                    if (id && field === 'description') {
                        updateDescription({ taskId, fileId: +(id || 0), description: value })
                            .unwrap()
                            .then((response) => {
                                if (!response?.success) {
                                    error('Во время обновления описания на сервере возникла ошибка');
                                }
                            });
                    }
                }}
            />
            { fileIdToDelete && <ConfirmDeleteModal callback={handleModalDeleteClose} />}
            {
                viewerIndex !== null
                && (
                    <AttachViewer
                        currentIndex={viewerIndex}
                        handleClose={handleAttachViewerClose}
                        slides={slides}
                    />
                )
            }
        </>
    );
}

export default FilesTableDataGrid;
