import React, { useCallback } from 'react';

import { usePricesAddToTaskMutation } from 'Endpoints/Prices/prices.api';

import { Modal } from 'Ui/Modal';

import container from 'Services/container';

import refreshSaleTask from '../models/refreshSaleTask.js';
import { IPriceListTreeTableSelectionWindowProps } from '../types/PriceListTreeTableSelectionWindow';
import { State } from '../utils/priceListTreeTableSelectors';

import PriceListTreeTableSelectionWindowFooter from './PriceListTreeTableSelectionWindowFooter';
import PriceListTreeTableView from './PriceListTreeTableView';

function PriceListTreeTableSelectionWindow({
    taskId,
    closeWindowCallback,
}: IPriceListTreeTableSelectionWindowProps) {
    const [addPrices] = usePricesAddToTaskMutation();

    const handleWindowClose = useCallback(() => {
        const { events } = container;
        events.emit('price__treeListTable:closeSelection');
        if (typeof closeWindowCallback === 'function') {
            closeWindowCallback();
        }
    }, [closeWindowCallback]);

    const handleSubmitButtonClick = useCallback(() => {
        const { store } = container;
        const storeState = store.getState() as State;
        const { priceListTreeTableReducer: { selectedPrices = [] } } = storeState;
        const data = {
            taskId,
            prices: selectedPrices.map(
                ({ id, count, saleProjectId }) => ({ id, count, projectId: saleProjectId }),
            ),
        };
        addPrices(data).unwrap().then((response) => {
            if (response?.summ || response?.ids?.length) {
                handleWindowClose();
                refreshSaleTask(taskId, response.ids);
            }
        });
    }, [addPrices, handleWindowClose, taskId]);

    return (
        <Modal
            open
            title="Подбор прайсов"
            width={1600}
            height={800}
            onClose={handleWindowClose}
            footer={
                (
                    <PriceListTreeTableSelectionWindowFooter
                        handleCloseButtonClick={handleWindowClose}
                        handleSubmitClick={handleSubmitButtonClick}
                    />
                )
            }
        >
            <PriceListTreeTableView />
        </Modal>
    );
}

export default PriceListTreeTableSelectionWindow;
